import { BaseStore } from "./base";
import { LangStore } from "./lang";
import { BurnDropStore } from "./burndrop";
import { GodStore } from "./god";
import { HomeStore } from "./home";
import { DripStore } from "./drip";
import { SettingStore } from "./setting";

export class RootStore {
  base = new BaseStore();
  lang = new LangStore();
  burnDrop = new BurnDropStore();
  god = new GodStore();
  home = new HomeStore(this);
  drip = new DripStore(this);
  setting = new SettingStore();
}
