import React, { useState } from "react";
import { Modal } from "antd";
import { useStore } from "../../../common/store/index";
import { useObserver } from "mobx-react-lite";
import { Header } from "../../components/Header";
import "./index.scss";

const logo = require("../../static/images/about-logo.png");
const table = require("../../static/images/about-table1.png");
const icon1 = require("../../static/images/icon01.png");
const icon2 = require("../../static/images/icon02.png");
const icon3 = require("../../static/images/icon03.png");
const icon4 = require("../../static/images/icon04.png");
const icon5 = require("../../static/images/icon05.png");

export const About = () => {
  const { lang } = useStore();
  const [showBigPic, setShowBigPic] = useState(false);

  return useObserver(() => (
    <div className="section__drop_about text-center">
      <Header />
      <div className="section__drop_about__content c-white text-left ">
        <div className="section__drop_about__content__title text-xl md:text-4xl mb-6 md:mb-12 font-light">{lang.t("about.title")}</div>
        <img src={logo} alt="" className="w-full mb-6 md:mb-12" />
        <div className="text-lg md:text-2xl font-regular mb-3 md:mb-6">{lang.t("about.paragraph.title1")}</div>
        <div className="text-md md:text-lg lineHeight font-regular mb-4">{lang.t("about.paragraph1.content1")}</div>
        <div className="text-md md:text-lg lineHeight font-regular mb-4">
          {lang.t("about.paragraph1.content2")}{" "}
          <a className="c-green" href="https://www.amazon.com/Pet-Camera-Detection-Blockchain-Authorization/dp/B08FDM5RZ5/" target="_blank" rel="Amazon">
            {lang.t("about.paragraph1.content3")}
          </a>{" "}
          {lang.t("about.paragraph1.content4")}{" "}
          <a
            className="c-green"
            href="https://www.tiktech.com/products/5454/iotex-&amp;-tenvis-ucam-security-camera---the-world's-first-blockchain-powered-home-security-camera"
            target="_blank"
            rel="other retailers"
          >
            {lang.t("about.paragraph1.content5")}
          </a>{" "}
          {lang.t("about.paragraph1.content6")}
        </div>
        <div className="text-md md:text-lg lineHeight font-regular mb-6 md:mb-12">
          {lang.t("about.paragraph1.content7")}{" "}
          <a className="c-green" href="https://medium.com/@iotex/native-staking-on-iotex-mainnet-ga-machina-is-live-5ab572a22d57" target="_blank" rel="other retailers">
            {lang.t("about.paragraph1.content77")}
          </a>
        </div>
        <div className="text-lg md:text-2xl font-regular mb-3 md:mb-6">{lang.t("about.paragraph6.title")}</div>
        <div className="text-md md:text-lg lineHeight font-regular mb-4">
          {lang.t("about.paragraph1.content8")}{" "}
          <a className="c-green" href="http://ucam.iotex.io" target="_blank" rel="Ucam">
            {lang.t("about.paragraph1.content9")}
          </a>{" "}
          {lang.t("about.paragraph1.content4")}{" "}
          <a
            className="c-green"
            href="https://www.nordicsemi.com/News/2020/02/IoTeXs-Cellular-IoT-based-tracker-delivers-environmental-and-condition-data-for-assets-in-transit"
            target="_blank"
            rel=""
          >
            {lang.t("about.paragraph1.content10")}
          </a>
          .
        </div>
        <div className="text-md md:text-lg lineHeight font-regular mb-6 md:mb-12">{lang.t("about.paragraph1.content11")}.</div>
        <div className="text-md md:text-xl lineHeight font-regular">{lang.t("about.paragraph2.content1")}</div>
        <ul className="list-disc text:md md:text-lg lineHeight font-regular mb-6 md:mb-12">
          <li>{lang.t("about.paragraph2.content2")}</li>
          <li>{lang.t("about.paragraph2.content3")}</li>
          <li>{lang.t("about.paragraph2.content4")}</li>
        </ul>
        <div className="text-md md:text-lg lineHeight font-regular mb-8 md:mb-16">{lang.t("about.paragraph2.content5")}</div>
        <div className="text-lg md:text-2xl lineHeight font-regular mb-2">{lang.t("about.paragraph3.title")} </div>
        <div className="text-md md:text-lg lineHeight font-regular mb-4">{lang.t("about.paragraph3.content1")}</div>
        <div className="text-md md:text-lg lineHeight font-regular mb-6 md:mb-12">{lang.t("about.paragraph3.content2")}</div>
        <img onClick={() => setShowBigPic(true)} src={table} className="w-full mb-12" alt="" />
        <div className="text-lg md:text-2xl font-regular mb-3 md:mb-6">{lang.t("about.paragraph4.title")}</div>
        <div className="text-md md:text-lg lineHeight font-regular mb-6 md:mb-12">{lang.t("about.paragraph4.content1")}</div>
        <div className="text-lg md:text-2xl font-regular mb-3 md:mb-6">{lang.t("about.paragraph5.title")}</div>
        <div className="text-md md:text-lg lineHeight font-regular mb-4">{lang.t("about.paragraph5.content1")}</div>
        <div className="text-md md:text-lg lineHeight font-regular mb-4">
          {lang.t("about.paragraph5.content2")}{" "}
          <a href="https://twitter.com/mimoprotocol/status/1321821560693350400?s=20" className="c-green" target="_blank" rel="ioWBTC">
            {lang.t("about.ioWBTC")}
          </a>{" "}
          {lang.t("about.paragraph5.content3")}
        </div>
        <div className="text-md md:text-lg lineHeight font-regular mb-4">{lang.t("about.paragraph5.content4")}</div>
        <div className="text-md md:text-lg lineHeight font-regular mb-6 md:mb-12">
          {lang.t("about.paragraph5.content5")}{" "}
          <a href="https://medium.com/@iotex/iotex-tokenomics-burn-drop-to-bootstrap-1-million-iotex-devices-66a43a1a68d7" target="_blank" className="c-green" rel="Medium blog">
            {lang.t("about.medium.blog")}
          </a>{" "}
          .
        </div>
        <div className="line mb-6 md:mb-12"></div>
        <div className="bottom-links flex items-center justify-center mb-6 md:mb-12">
          <a href="https://iotex.io/" target="_blank">
            <img src={icon1} alt="" />
          </a>
          <a href="https://twitter.com/iotex_io" target="_blank">
            <img src={icon2} alt="" />
          </a>
          <a href="https://t.me/IoTeXGroup" target="_blank">
            <img src={icon3} alt="" />
          </a>
          <a href="https://www.reddit.com/r/IoTeX" target="_blank">
            <img src={icon4} alt="" />
          </a>
          <a href="https://medium.com/@iotex" target="_blank">
            <img src={icon5} alt="" />
          </a>
        </div>
        <div className="text-sm md:text-base text-center">{lang.t("about.bottom")}</div>
        {showBigPic && (
          <div className="modal-pop fixed" onClick={() => setShowBigPic(false)}>
            <div className="ant-modal-mask"></div>
            <div className="modal-pop-content flex justify-center items-center">
              <img src={table} alt="" />
            </div>
          </div>
        )}
      </div>
    </div>
  ));
};
