import { ChainState } from "../src/common/store/lib/ChainState";
import { TokenState } from "../src/common/store/lib/TokenState";

export const IotexTestnetConfig = new ChainState({
  name: "Iotex Testnet",
  chainId: 4690,
  rpcUrl: "https://api.testnet.iotex.one:443",
  explorerURL: "https://testnet.iotexscan.io",
  explorerName: "IotexScan",
  info: {
    blockPerSeconds: 5,
    multicallAddr: "io1fvghae49wh3nvjfmsg63wz0gc7ukrhlkhemxc0",
  },
  Coin: new TokenState({
    symbol: "IOTX",
    decimals: 18,
  }),
});

export const IotexMainnetConfig = new ChainState({
  name: "Iotex Mainnet",
  chainId: 4689,
  rpcUrl: "https://api.iotex.one:443",
  explorerURL: "https://iotexscan.io",
  explorerName: "IotexScan",
  info: {
    blockPerSeconds: 5,
    multicallAddr: "io14n8zjjlh6f0733wxftj9r97ns78ksspmjgzh7e",
  },
  Coin: new TokenState({
    symbol: "IOTX",
    decimals: 18,
  }),
});
