import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { Home } from "./pages/Home";
import { History } from "./pages/History";
import { About } from "./pages/About";
import { GetAirdrip } from "./pages/GetAirdrip";
import { ProvideAirDrip } from "./pages/ProvideAirdrip";
import { Toaster } from "react-hot-toast";

import "./App.scss";
import { useStore } from "../common/store/index";
import { MainLayout } from "./layouts/MainLayout";
import { ClientOnly } from "./components/ClientOnly/clientOnly";

const App = () => {
  //@ts-ignore
  const { lang } = useStore();
  useEffect(() => {
    lang.init();
  }, []);

  return (
    <ClientOnly>
      <Toaster />
      <MainLayout>
        <Switch>
          <Route exact={true} path="/" component={Home} />
          <Route path="/history" component={History} />
          <Route path="/about" component={About} />
          <Route path="/get-airdrip" component={GetAirdrip} />
          <Route path="/provide-airdrip" component={ProvideAirDrip} />
        </Switch>
      </MainLayout>
    </ClientOnly>
  );
};

export default App;
