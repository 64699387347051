import { IotexNetworkState } from "../src/common/store/lib/IotexNetworkState";
import { MappingState } from "../src/common/store/lib/MappingState";
import { IotexMainnetConfig, IotexTestnetConfig } from "./IotexMainnetConfig";

export const IotexNetworkConfig = new IotexNetworkState({
  allowChains: [IotexMainnetConfig.chainId, IotexTestnetConfig.chainId],
  chain: new MappingState({
    currentId: IotexMainnetConfig.chainId,
    map: {
      [IotexMainnetConfig.chainId]: IotexMainnetConfig,
      [IotexTestnetConfig.chainId]: IotexTestnetConfig,
    },
  }),
});
