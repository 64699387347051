import React, { useState, useEffect } from "react";

/**
 * 图片加载失败就显示默认图片
 * 使用hook方式
 * @param {*} src  图片路径
 * @param {*} style  图片样式
 * @param {*} defaultImg  默认显示的图片路径
 */
const TokenAvatar = ({ src, defaultImg }) => {
  const [imgSrc, handleImageErrored] = useState(src);

  return <img className="w-5 md:w-10 mr-2 md:mr-4 rounded-full" src={imgSrc} onError={() => handleImageErrored(defaultImg)} />;
};
export default TokenAvatar;
