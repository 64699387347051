import React from "react";
import { Modal } from "antd";
import { useStore } from "../../../common/store/index";
import "./index.scss";

interface IComponentProps {
  isModalVisible?: boolean;
  handleOk?: any;
  handleCancel?: any;
}

export const ConnectDialog = (props: IComponentProps) => {
  const { lang } = useStore();
  const { isModalVisible, handleOk, handleCancel } = props;

  return (
    <article>
      <Modal wrapClassName="connectDialog" title={lang.t("connect.title")} visible={isModalVisible} onOk={handleOk} footer={null} closable={false}>
        <p className="text-base">{lang.t("connect.tips")}</p>
        <p>
          {lang.t("new.iopay")}{" "}
          <a href="https://iopay-wallet.iotex.io/" className="underline" style={{ color: "rgba(3, 255, 225, 0.8)" }}>
            {lang.t("learn.more.wallets")}
          </a>{" "}
        </p>
        <div className="simple-button text-center" onClick={handleCancel}>
          {lang.t("ok")}
        </div>
      </Modal>
    </article>
  );
};
