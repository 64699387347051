import { EventEmitter } from "events";
import TypedEmitter from "typed-emitter";

interface MessageEvents {
  "client.inited": () => void;
  "client.iopay.connected": () => void;
  "client.iopay.connectError": () => void;
  "client.iopay.close": () => void;
  "client.wallet.onAccount": () => void;
  "client.wallet.iotx.onBalance": () => void;
  "client.addLiquidity.onSupply": ({ actionHash: string }) => void;
  "client.addLiquidity.onRemove": ({ actionHash: string }) => void;
  "client.swap.onSwap": ({ actionHash: string }) => void;
  "client.action.approve.success": ({ actionHash: string }) => void;
}

export const eventBus = new EventEmitter() as TypedEmitter<MessageEvents>;
