import { utils } from "../src/common/utils/index";
import BN from "bignumber.js";

const {
  NODE_ENV,
  API_ENDPOINT,
  IOTEX_ANALYTICS_ENDPOINT,
  BURNDROP_CONTRACT_ADDRESS,
  IOTEX_CORE_ENDPOPINT,
  FACTORY_CONTRACT_ADDRESS,
  BATCHMINTER_ADDRESS,
  RUN_CRONJOB,
  FORCE_HTTPS,
  IOTT_ENDPOINT,
  DEVICES_ON_PHASE,
} = utils.env.getEnv();
const IS_PROD = NODE_ENV == "production";

export const publicConfig = {
  IS_PROD,
  NODE_ENV,
  API_ENDPOINT: API_ENDPOINT || "http://localhost:3000",
  IOTEX_ANALYTICS_ENDPOINT: IOTEX_ANALYTICS_ENDPOINT || "https://analytics.iotexscan.io",
  BURNDROP_CONTRACT_ADDRESS: BURNDROP_CONTRACT_ADDRESS || "io166z7zqnfq6hxat5ff7c58l0xytgercnnqdzly7",
  FACTORY_CONTRACT_ADDRESS: FACTORY_CONTRACT_ADDRESS || (IS_PROD ? "io1w0sytg3w8fl6zewp4r38w4w9dlq6765uyk9s8j" : "io1vu0tq2v6ph5xhwrrpx0vzvg5wt8adfk3ygnxfj"),
  BATCHMINTER_ADDRESS: BATCHMINTER_ADDRESS || "io1h67af0ftk3598nv89rc4gwd5t63w2ty70wsg7r",
  IOTEX_CORE_ENDPOPINT: IOTEX_CORE_ENDPOPINT || "https://api.testnet.iotex.one:443",
  RUN_CRONJOB: utils.env.getBoolean(RUN_CRONJOB),
  FORCE_HTTPS: utils.env.getBoolean(FORCE_HTTPS),
  IOTT_ENDPOINT: IOTT_ENDPOINT || "https://iott.iotex.io",
  DEVICES_ON_PHASE,
  maxApprove: new BN(1.157920892373162e59).toFixed(0),
  BLOCK_APIENDPOINT: IS_PROD ? "https://graph.mimo.exchange/subgraphs/name/common/blocks/graphql" : "https://graph.mimo.exchange/subgraphs/name/common_test/blocks",
  IOTEX_SCAN_IO: IS_PROD ? "https://iotexscan.io/token/" : "https://testnet.iotexscan.io/token/",
};
