import { makeAutoObservable, toJS } from "mobx";
import DripAbi from "../../constants/drip.json";
import AccountAbi from "../../constants/account.json";
import SccountAbi from "../../constants/strategy.json";
import XRC20ABI from "../../constants/XRC20.json";
import { CallParams } from "./type";
import { RootStore } from "../Root";
import { _ } from "../../utils/lodash";
import { BigNumberState } from "../type";
import { NetworkState } from "./NetworkState";
import BigNumber from "bignumber.js";
import { utils } from "../../utils/index";
import { BigNumberInputState } from "./BignumberInputState";
export class PoolState {
  id: number;
  show: boolean;
  airdrip: DripState;

  startBlock = 0;
  endBlock = 0;
  lastDripBlock = 0;
  volume = new BigNumberState({ loading: true });
  cloudVolume = new BigNumberState({ loading: true });
  konstante = new BigNumberState({ loading: true });
  exists: boolean = false;

  dripPerBlock = new BigNumberState({ loading: true });
  exchangeRate = new BigNumberState({ loading: false });
  actuallyRate = new BigNumberState({ loading: false });

  amount = new BigNumberInputState({ loading: true });
  points = new BigNumberState({ loading: true });

  isRedeem: boolean = false;

  get amountStatus() {
    console.log("amount status", this.ended ? true : this.amount.value.comparedTo(0) > 0);
    return this.ended ? true : this.amount.value.comparedTo(0) > 0;
  }

  get pointStatus() {
    console.log("point status", this.airdrip.account.value.toNumber(), this.points.value, this.airdrip.account.value.comparedTo(this.points.value) > 0);
    return this.airdrip.account.value.comparedTo(this.points.value) >= 0;
  }

  get ended() {
    return this.airdrip.rootStore.drip.state.latestBlock.height >= this.endBlock + this.airdrip.fadeoutDuration;
  }

  get exchangeAmount() {
    return this.ended ? this.volume : this.exchangeRate;
  }

  get exchangeActuallyAmount() {
    return this.ended ? this.volume : this.actuallyRate;
  }

  constructor(args: Partial<PoolState>) {
    Object.assign(this, args);
    makeAutoObservable(this);
  }

  setValue(args: { cloudVolume: BigNumber; endBlock: BigNumber; exists: boolean; konstante: BigNumber; lastDripBlock: BigNumber; startBlock: BigNumber; volume: BigNumber }) {
    this.startBlock = args.startBlock.toNumber();
    this.endBlock = args.endBlock.toNumber();
    this.exists = args.exists;
    this.konstante.setValue(args.konstante);
    this.volume.setValue(new BigNumber(args.volume.toString()).dividedBy(10 ** 12));
    this.cloudVolume.setValue(new BigNumber(args.cloudVolume.toString()));
    this.lastDripBlock = args.lastDripBlock.toNumber();
  }
}

export class DripTokenState {
  abi = XRC20ABI;
  address: string = "";
  symbol: string = "";
  decimals: number = 0;

  presetSymbol: string = "";
  presetIcon: string = "";

  tokenName: string = "";
  network: NetworkState;
  pool = new PoolState({});
  airdrip: DripState;
  allowanceForDrip = new BigNumberState({ loading: true });
  balance = new BigNumberState({ loading: true });

  website: string = "";
  isShow: boolean = false;
  valid: boolean = false;
  isActive: boolean = false;
  metas: {
    isApprovingAllowance?: boolean;
    [key: string]: any;
  } = {};
  constructor(args: Partial<DripTokenState>) {
    Object.assign(this, args);
    this.balance.setDecimals(this.decimals);
    makeAutoObservable(this);
  }

  get tokenSymbol() {
    return this.presetSymbol || this.symbol;
  }

  get tokenIcon() {
    return this.presetIcon || `https://githubproxy.b-cdn.net/iotexproject/iotex-token-metadata/master/images/${this.address}.png`;
  }

  get isMineAdd() {
    return _.indexOf(this.airdrip.storageList, this.address) !== -1;
  }

  tokenStatus({ amount, balance }: { amount: BigNumberInputState; balance: BigNumberState }) {
    const required = amount.value.comparedTo(0) > 0;
    if (this.balance.loading) {
      return { required, enough: true };
    }
    const enough = balance.value.comparedTo(amount.value) > 0;
    return {
      required,
      enough: enough,
      valid: required ? enough : false,
    };
  }

  setDecimals(val: number) {
    this.decimals = val;
    this.balance.setDecimals(this.decimals);
  }

  parseValue({ value, fixed = 6 }: { value: any; fixed?: number }) {
    return new BigNumber(value).dividedBy(10 ** this.decimals).toFixed(fixed, 1);
  }

  preMulticall(args: Partial<CallParams>) {
    return Object.assign({ address: this.address, abi: this.abi }, args);
  }

  resetExchangeRate({ token, address }: { token: DripTokenState; address: string }) {
    this.network.multicall([
      this.airdrip.preMulticall({
        method: "redeemAmount",
        params: [address, "1"],
        handler: (v) => {
          token.pool.actuallyRate.setDecimals(token.decimals);
          token.pool.actuallyRate.setValue(new BigNumber(v.toString()));
        },
      }),
    ]);
  }

  loadTokenData() {
    return this.network.multicall([
      this.preMulticall({ method: "name", handler: (v) => (this.tokenName = v) }),
      this.preMulticall({
        method: "symbol",
        handler: (v) => {
          this.symbol = v;
        },
      }),
    ]);
  }

  loadBalanceOf() {
    return this.network.multicall([
      this.preMulticall({ method: "decimals", handler: (v) => (this.decimals = v) }),
      this.preMulticall({ method: "allowance", params: [this.network.account, this.airdrip.address], handler: this.allowanceForDrip }),
      this.preMulticall({
        method: "balanceOf",
        params: [this.network.account],
        handler: (v) => {
          this.balance.decimals = this.decimals;
          this.balance.setValue(new BigNumber(v.toString()));
        },
      }),
    ]);
  }

  transfer(args: Partial<CallParams>) {
    return this.network.execContract(Object.assign({ address: this.address, abi: this.abi, method: "transfer" }, args));
  }

  approve(args: Partial<CallParams<[string, any]>>) {
    return this.network.execContract(Object.assign({ address: this.address, abi: this.abi, method: "approve" }, args));
  }
}

export class DripState {
  cacheKey: string;
  address: string;
  beingDropped: number;
  abi = DripAbi;
  list: DripTokenState[];
  storageList: string[] = [];
  network: NetworkState;
  defaultList: DripTokenState[];

  rootStore: RootStore;

  maxDuration = new BigNumberState({});
  fadeoutDuration = 0;
  exchangeRate = new BigNumberState({});
  account = new BigNumberState({});
  since = new BigNumberState({});

  constructor(args: Partial<DripState>) {
    Object.assign(this, args);
    utils.env.onBrowser(() => {
      this.defaultList = this.list;
      this.getCache();
    });

    makeAutoObservable(this);
  }

  addToken(address: string) {
    this.storageList = _.union([...this.storageList, address]);
    this.setCache();
    this.getCache();
    return this;
  }

  removeToken(address: string) {
    this.storageList = _.pull([...this.storageList], address);
    this.setCache();
    this.getCache();
    return this;
  }

  setCache() {
    const { storageList } = this;
    localStorage.setItem(
      this.cacheKey,
      JSON.stringify({
        storageList: _.union(storageList),
      })
    );
  }

  getCache() {
    const _data = localStorage.getItem(this.cacheKey);
    if (_data) {
      const { storageList = [] } = JSON.parse(_data) as any;
      this.storageList = storageList;
      this.list = [...this.defaultList, ...storageList.map((i) => new DripTokenState({ address: i, isShow: false, valid: true }))];

      console.log(_data, this.list);
    }
    this.list &&
      this.list.forEach((i) => {
        i.airdrip = this;
        i.pool.airdrip = this;
        i.network = this.network;
      });
    return this;
  }

  loadPoolOf(token: DripTokenState) {
    return this.network.multicall([
      this.preMulticall({
        method: "poolOf",
        params: [token.address],
        handler: (v) => {
          token.pool.setValue(v);
        },
      }),
      this.preMulticall({
        method: "volumeToDripPerBlock",
        params: [token.address],
        handler: (v) => {
          token.pool.dripPerBlock.setValue(new BigNumber(v.toString()).dividedBy(10 ** 12));
        },
      }),
    ]);
  }

  redeemAmount({ token, address, points }: { token: DripTokenState; address: string; points: number }) {
    this.network.multicall([
      this.preMulticall({
        method: "redeemAmount",
        params: [address, points],
        handler: (v) => {
          token.pool.amount.setValue(new BigNumber(v.toNumber()));
          token.pool.points.setValue(new BigNumber(points));
        },
      }),
    ]);
  }

  redeem(args: Partial<CallParams<[string, number, number]>>) {
    console.log(utils.env.isMobile());
    const data = utils.env.isMobile() ? { address: this.address, abi: this.abi, method: "redeem" } : { address: this.address, abi: this.abi, method: "redeem", options: { gasLimit: "1000000" } };
    return this.network.execContract(Object.assign(data, args));
  }

  addAsset(args: Partial<CallParams<[string, number, number, number]>>) {
    return this.network.execContract(Object.assign({ address: this.address, abi: this.abi, method: "addAsset" }, args));
  }

  increaseSupply(args: Partial<CallParams<[string, number]>>) {
    return this.network.execContract(Object.assign({ address: this.address, abi: this.abi, method: "increaseSupply" }, args));
  }

  extendDuration(args: Partial<CallParams<[string, number]>>) {
    return this.network.execContract(Object.assign({ address: this.address, abi: this.abi, method: "extendDuration" }, args));
  }

  preAccountMulticall(args: Partial<CallParams>) {
    return Object.assign({ address: this.address, abi: this.abi }, args);
  }

  preMulticall(args: Partial<CallParams>) {
    return Object.assign({ address: this.address, abi: this.abi }, args);
  }
}

export class AccountantState {
  address: string;
  abi = AccountAbi;
  network: NetworkState;
  expireAt = new BigNumberState({});
  term = new BigNumberState({});

  constructor(args: Partial<DripState>) {
    Object.assign(this, args);
    makeAutoObservable(this);
  }

  registerStatus() {
    // 0 register first, 1 expireAt <= term register again, 2 expireAt > term last day
    let status = 0;
    const expireAtValue = this.expireAt.value.toNumber();
    const termValue = this.term.value.toNumber();
    console.log(expireAtValue, termValue);
    if (expireAtValue === 0) {
      status = 0;
    } else {
      if (expireAtValue <= termValue) {
        status = 1;
      }
      if (expireAtValue > termValue) {
        status = 2;
      }
    }
    console.log({ status, day: expireAtValue - termValue });
    return { status, day: expireAtValue - termValue };
  }

  register(args: Partial<CallParams>) {
    return this.network.execContract(Object.assign({ address: this.address, abi: this.abi, method: "register" }, args));
  }

  preMulticall(args: Partial<CallParams>) {
    return Object.assign({ address: this.address, abi: this.abi }, args);
  }
}

export class StrategyState {
  address: string;
  abi = SccountAbi;
  network: NetworkState;

  constructor(args: Partial<DripState>) {
    Object.assign(this, args);
    makeAutoObservable(this);
  }

  register(args: Partial<CallParams>) {
    return this.network.execContract(Object.assign({ address: this.address, abi: this.abi, method: "register" }, args));
  }

  preMulticall(args: Partial<CallParams>) {
    return Object.assign({ address: this.address, abi: this.abi }, args);
  }
}
