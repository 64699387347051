import { makeAutoObservable } from "mobx";
import { publicConfig } from "../../../configs/public";
import { AccountantState, StrategyState } from "./lib/DripState";
import BigNumber from "bignumber.js";
import { _ } from "../utils/lodash";
import { RootStore } from "./Root";
import { TestNetDripTokenConfig, MainNetDripTokenConfig } from "../../../configs/ExchangeDripConfig";
import { IotexTestnetConfig, IotexMainnetConfig } from "../../../configs/IotexMainnetConfig";
import dayjs from "../../client/utils/day";
import toast from "react-hot-toast";
import { fromBytes } from "iotex-antenna/lib/crypto/address";
import { IotexNetworkConfig } from "../../../configs/NetworkConfig";

export class DripStore {
  rootStore: RootStore;
  state = {
    latestBlock: {
      height: 0,
      timetamp: 0,
    },
  };
  configs = {
    [IotexTestnetConfig.chainId]: TestNetDripTokenConfig,
    [IotexMainnetConfig.chainId]: MainNetDripTokenConfig,
  };
  accountant = null;
  strategy = null;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    TestNetDripTokenConfig.rootStore = rootStore;
    MainNetDripTokenConfig.rootStore = rootStore;
    makeAutoObservable(this);
  }

  get config() {
    return this.configs[this.god.currentChain.chainId];
  }

  get god() {
    return this.rootStore.god;
  }

  getDateByHeight({ height }: { height: number }) {
    if (this.state.latestBlock.height == 0) return "...";
    const heightDiff = height - this.state.latestBlock.height;
    return dayjs
      .unix(this.state.latestBlock.timetamp)
      .add(heightDiff * 5, "second")
      .format("L LT");
  }

  async addToken(address: string) {
    try {
      this.god.currentNetwork.multicall([
        this.config.preMulticall({
          method: "redeemAmount",
          params: [address, 1],
          handler: (v) => {
            this.config.addToken(address);
          },
        }),
      ]);
    } catch (error) {
      toast.error(this.rootStore.lang.t("assets.no.pool", { address }));
    }
  }

  async loadPublicData() {
    // load block data

    const chainMeta = await this.god.iotex.antenna.iotx.getChainMeta({});
    // const blockMeta = await this.god.iotex.antenna.iotx.getBlockMetas({ byIndex: { count: 1, start: Number(chainMeta.chainMeta.height) } });
    //@ts-ignore
    this.state.latestBlock.height = Number(chainMeta.chainMeta.height);
    //@ts-ignore
    // this.state.latestBlock.timetamp = Number(blockMeta.blkMetas[0].timestamp.toString());
    this.state.latestBlock.timetamp = 0;

    // blockClient
    //   .query({
    //     blocks: [
    //       //@ts-ignore
    //       { first: 1, orderBy: "number", orderDirection: "desc" },
    //       { number: 1, timestamp: 1 },
    //     ],
    //   })
    //   .then((res) => {
    //     this.state.latestBlock.height = res.data.blocks[0].number;
    //     this.state.latestBlock.timetamp = res.data.blocks[0].timestamp;
    //   });
    // load contract data
    console.log("yyyyyy");
    const tokenList = this.config.list.filter((i) => i.valid);
    console.log("xxxxx");
    await this.god.currentNetwork.multicall([
      this.config.preMulticall({
        method: "accountant",
        handler: (v) => {
          const ioAddress = fromBytes(Buffer.from(String(v).replace(/^0x/, ""), "hex")).string();
          console.log("ioAddress", ioAddress);
          this.accountant = new AccountantState({ address: ioAddress, network: IotexNetworkConfig });
          this.accountant.preMulticall({ method: "exchangeRate", handler: (v) => this.config.exchangeRate.setValue(new BigNumber(v.toString())) });
        },
      }),
      this.config.preMulticall({
        method: "strategy",
        handler: (v) => {
          console.log(v);
          const ioAddress = fromBytes(Buffer.from(String(v).replace(/^0x/, ""), "hex")).string();
          this.strategy = new StrategyState({ address: ioAddress, network: IotexNetworkConfig });
          this.strategy.preMulticall({
            method: "fadeoutDuration",
            handler: (v) => {
              this.config.fadeoutDuration = Number(v.toString());
            },
          });
        },
      }),
      this.config.preMulticall({ method: "maxDuration", handler: (v) => this.config.maxDuration.setValue(new BigNumber(v.toString())) }),
      ...tokenList.map((item) =>
        this.config.preMulticall({
          method: "volumeToDripPerBlock",
          params: [item.address],
          handler: (v) => {
            item.pool.dripPerBlock.setValue(new BigNumber(v.toString()).dividedBy(10 ** 12));
          },
        })
      ),
      ...tokenList.map((item) => item.preMulticall({ method: "name", handler: (v) => (item.tokenName = v) })),
      ...tokenList.map((item) => item.preMulticall({ method: "symbol", handler: (v) => (item.symbol = v) })),
      ...tokenList.map((item) =>
        item.preMulticall({
          method: "decimals",
          handler: (v) => {
            item.setDecimals(v);
            item.balance.setDecimals(v);
            item.pool.amount.setDecimals(v);
          },
        })
      ),
      ...tokenList.map((item) =>
        this.config.preMulticall({
          method: "poolOf",
          params: [item.address],
          handler: (v) => {
            item.pool.setValue(v);
          },
        })
      ),
    ]);
    const validList = tokenList.filter((i) => !i.pool.ended);
    if (validList.length > 0) {
      await this.god.currentNetwork.multicall([
        ...tokenList.map((item) =>
          this.config.preMulticall({
            method: "redeemAmount",
            params: [item.address, "1"],
            handler: (v) => {
              item.pool.exchangeRate.setDecimals(item.decimals);
              item.pool.exchangeRate.setValue(new BigNumber(v.toString()));
              item.pool.actuallyRate.setDecimals(item.decimals);
              item.pool.actuallyRate.setValue(new BigNumber(v.toString()));
            },
          })
        ),
      ]);
    }
  }

  async loadPrivateData() {
    const tokenList = this.config.list.filter((i) => i.valid);
    await this.god.currentNetwork.multicall([
      // points value
      this.accountant.preMulticall({
        method: "balanceOf",
        params: [this.god.currentNetwork.account],
        handler: (v) => {
          console.log("xxxxxx1", this.config.account.value);
          this.config.account.setValue(new BigNumber(v.toString()));
        },
      }),
      this.accountant.preMulticall({
        method: "accounts",
        params: [this.god.currentNetwork.account],
        handler: (v) => {
          this.accountant.expireAt.setValue(new BigNumber(v.expireAt.toString()));
        },
      }),
      // term
      this.accountant.preMulticall({
        method: "term",
        handler: (v) => {
          console.log("term", v.toString());
          this.accountant.term.setValue(new BigNumber(v.toString()));
        },
      }),
      ...tokenList.map((item) => item.preMulticall({ method: "balanceOf", params: [this.god.currentNetwork.account], handler: item.balance })),
      // token balanceOf
      ...this.config.list
        .filter((i) => i.valid)
        .map((item) =>
          item.preMulticall({
            method: "balanceOf",
            params: [this.god.currentNetwork.account],
            handler: (v) => {
              item.balance.setValue(new BigNumber(v.toString()));
            },
          })
        ),
    ]);
  }
}
