import React, { useEffect } from "react";
import "./index.scss";
import { numberWithCommas } from "../../utils";
import { useCountUp } from "react-countup";

interface IComponentProps {
  value: number;
  label?: any;
  icon: string;
  unit: string;
  decimals?: number;
  tips: any;
}

export const StateItem = (props: IComponentProps) => {
  const { value, label, icon, unit, decimals = 0, tips } = props;
  const { countUp, update } = useCountUp({
    start: 0,
    end: value,
    delay: 0,
    duration: 1,
    decimals,
  });

  useEffect(() => {
    update(value);
  }, [value]);

  return (
    <div className="item__state text-center c-white mb-8 md:mb-0">
      {icon && <img src={icon} alt="" className="item__state__img mb-2 mx-auto" />}
      <div className="text-2xl xl:text-4xl mb-2 tracking-wide font-normal">
        {unit || ""}
        {numberWithCommas(countUp) || 0}
        {tips ? tips : ""}
      </div>
      {label && <div className="tracking-wide text-sm md:text-base xl:text-xl">{label}</div>}
    </div>
  );
};
