import React, { useEffect } from "react";
import "./index.scss";
import { useStore } from "../../../common/store/index";
import { InputNumber, message, Tooltip, Alert } from "antd";
import { useObserver, useLocalStore } from "mobx-react-lite";
import { Header } from "../../components/Header";
import { observer } from "mobx-react";
import { ConnectDialog } from "../../components/ConnectDialog";
import { AddPoolDialog } from "../../components/AddPoolDialog";
import { IotexProvider } from "../../components/IotexProvider";
import { DripTokenState } from "../../../common/store/lib/DripState";
import { utils } from "../../../common/utils/index";
import { useRouter } from "../../utils/react-hooks";
import BigNumber from "bignumber.js";
import TokenAvatar from "../../components/TokenAvatar";
import { publicConfig } from "../../../../configs/public";

const addressLogo = require("../../static/images/icon_iopay.png");
const upArrow = require("../../static/images/up_arrow.png");
const downArrow = require("../../static/images/down_arrow.png");
const exclamation = require("../../static/images/exclamation.png");

export const GetAirdrip = observer(() => {
  const { lang, god, drip } = useStore();

  const router = useRouter();
  const store = useLocalStore(() => ({
    isModalVisible: false,
    isAddModalVisible: false,
    amount: 1,
    points: 1,
    signing: false,
    get TotalPointsAmout() {
      return god.currentNetwork.account ? drip.config.account.value.toString() : "...";
    },
    get registerStatusObj() {
      return drip.accountant == null ? 0 : drip.accountant.registerStatus();
    },
    onConnectWallet() {
      store.isModalVisible = true;
      god.iotex.activeConnector();
    },
    toggleShow(item: DripTokenState, index: number) {
      drip.config.list.map((item, o) => {
        if (o !== index) item.isShow = false;
      });
      let oldList = [...drip.config.list];
      oldList[index].isShow = !item.isShow;
      drip.config.list = oldList;
    },
    onAddPool() {
      store.isAddModalVisible = true;
    },
    onAddToken(address: string) {
      store.isAddModalVisible = false;
      drip.addToken(address);
      drip.loadPublicData();
    },
    onRemoveToken(address: string) {
      drip.config.removeToken(address);
      drip.loadPublicData();
    },
    async setPointsMax(item: DripTokenState) {
      console.log("setPointsMax----", item.pool.volume.value, item.balance.value, item.pool.volume.value.comparedTo(item.balance.value) > 0);
      item.pool.points.setValue(drip.config.account.value);
      store.pointsToAmount(drip.config.account.value.toNumber(), item);
    },
    async setPointsValue(value: any, item: DripTokenState) {
      if (value > 0) {
        const status = value > drip.config.account.value.toNumber();
        item.pool.points.setValue(status ? drip.config.account.value.toNumber() : value.toString());
        store.pointsToAmount(status ? drip.config.account.value.toNumber() : value, item);
      }
    },
    async pointsToAmount(value: any, item: DripTokenState) {
      const [data] = await god.currentNetwork.multicall([drip.config.preMulticall({ method: "redeemAmount", params: [item.address, value] })]);
      item.pool.amount.setValue(new BigNumber(data.toString()));
      item.resetExchangeRate({ token: item, address: item.address });
    },
    async setAmountMax(item: DripTokenState) {
      item.pool.amount.setFormat(item.pool.volume.format);
      store.amountToPoints(item);
    },
    async setAmountValue(value: any, item: DripTokenState) {
      if (value > 0) {
        const status = value > Number(item.pool.volume.format);
        item.pool.amount.setFormat(status ? item.pool.volume.format : new BigNumber(value));
        store.amountToPoints(item);
      }
    },
    async amountToPoints(item: DripTokenState) {
      const [data] = await god.currentNetwork.multicall([drip.config.preMulticall({ method: "redeemExactAssetCost", params: [item.address, item.pool.amount.value.toFixed(0, 1)] })]);
      item.pool.points.setValue(new BigNumber(data.toString()));
    },
    async redeem(index: number) {
      const item = drip.config.list[index];
      item.pool.isRedeem = true;
      try {
        if (item.pool.ended) {
          item.pool.points.setValue(new BigNumber(1));
          item.pool.amount.setFormat(item.pool.volume.format);
        }
        const res = await drip.config.redeem({ params: [item.address, item.pool.amount.value.toFixed(0), item.pool.points.value.toString()] });
        await res.wait();
        if (res.hash) {
          message.success(`hash: ${res.hash}`);
          item.pool.isRedeem = false;
          item.pool.amount.setValue(new BigNumber(0));
          item.pool.points.setValue(new BigNumber(0));
          drip.loadPublicData();
          drip.loadPrivateData();
        }
      } catch (error) {
        console.log("error===>", error);
        item.pool.isRedeem = false;
        message.warning("Redeem Failed");
      }
    },
    async register() {
      if (!god.currentNetwork.account) {
        store.onConnectWallet();
        return;
      }
      if (god.currentChain.Coin.balance.value.comparedTo(0) <= 0) {
        message.warning(lang.t("iotx.insufficient.balance"));
        return;
      }
      try {
        store.signing = true;
        const res = await drip.accountant.register({});
        await res.wait();
        console.log("register", res);
        if (res.hash) {
          message.success(lang.t("register.success"));
          message.success(`hash: ${res.hash}`);
          store.signing = false;
          drip.loadPrivateData();
        }
        this.signing = false;
      } catch (error) {
        console.log("error===>", error);
        store.signing = false;
        message.warning("Approve Failed");
      }
    },
  }));

  useEffect(() => {
    drip.loadPublicData();
    if (router.query.token) {
      drip.addToken(router.query.token);
    }
  }, []);

  useEffect(() => {
    if (god.currentNetwork.account) {
      god.loadBalance();
      console.log("xxx", drip.accountant);
      if (drip.accountant) drip.loadPrivateData();
      console.log(god.currentChain.Coin.balance, god.currentChain.chainId);
    }
  }, [god.currentNetwork.account, drip.accountant]);

  return useObserver(() => (
    <>
      <IotexProvider />
      <div className="bd-aridrip_container c-white flex flex-col">
        <Header />
        {/* overflow-hidden */}
        <article className="flex-1 flex flex-col ">
          <div className="bd-aridrip_title text-xl lg:text-2xl xl:text-3xl">{lang.t("wellcome")}</div>
          <div className="text-center m-auto mb-8 w-11/12 md:w-1/2">
            <p className="font-thin text-xs md:text-sm mb-5">{lang.t("airdrip.subtitle")}</p>
            <a href="https://iotex.io/blog/airdrip-everything-you-need-to-know/" className="c-cd5 font-normal underline">{`For more details >>`}</a>
          </div>

          {/* overflow-y-auto */}

          <div className="bd-aridrip_content flex-1 px-4 md:px-12 lg:px-12 xl:px-24 ">
            <div className="px-2 py-2 md:px-5 mb-1 text-xs" style={{ background: "rgba(255,255,255, 0.05)", color: "#38dcd5" }}>
              {lang.t("airdrip.top.tips")}
            </div>
            <div className="w-full" style={{ background: "rgba(255,255,255, 0.05)" }}>
              {/*header point */}
              <div className="bd-aridrip_point flex justify-between items-center px-2 py-3 md:px-5 md:py-6">
                {/* {!store.registerStatusObj || store.registerStatusObj.status === 0 || store.registerStatusObj.status === 1 ? (
                  <div className="flex items-center text-xs md:text-xl ">
                    <div
                      className={`simple-button text-xs md:text-base disabledBtn text-center px-3 cursor-pointer mr-2 ${store.signing ? "loadingBtn" : ""}`}
                      style={{ marginTop: 0, lineHeight: "32px" }}
                      onClick={store.register}
                    >
                      {lang.t("register")}
                    </div>
                    <Tooltip
                      placement="right"
                      color="rgba(255,255,255,0.9)"
                      title={!store.registerStatusObj || store.registerStatusObj.status === 0 ? lang.t("sign.up.tips") : lang.t("sign.up.tips2")}
                    >
                      <img src={exclamation} alt="" className="w-4 ml-1 inline-block" />
                    </Tooltip>
                  </div>
                ) : (
                  <div className="flex flex-col">
                    <div className="text-xs md:text-xl mb-0.5">
                      {lang.t("my.point")} ：<span className="c-cd5">{store.TotalPointsAmout}</span>
                    </div>
                    <div className={`flex text-xs md:text-sm ${store.registerStatusObj < 7 ? "text-red-600" : ""} flex-col md:flex-row items-start md:items-center`}>
                      {lang.t("sign.up.tips3", {
                        value: store.registerStatusObj.day,
                      })}
                      {store.registerStatusObj && store.registerStatusObj.status === 2 && store.registerStatusObj.day < 7 ? (
                        <div
                          className={`plan-button text-center px-3 cursor-pointer md-0 md:ml-4 ${store.signing ? "loadingBtn" : ""}`}
                          style={{ marginTop: 0, backgroundColor: "transparent !important" }}
                          onClick={store.register}
                        >
                          {lang.t("renew")}
                        </div>
                      ) : (
                        <div />
                      )}
                    </div>
                  </div>
                )} */}
                <div className="flex flex-col">
                  <div className="text-xs md:text-xl mb-0.5">
                    {lang.t("my.point")} ：<span className="c-cd5">{store.TotalPointsAmout}</span>
                  </div>
                </div>
                <div className="flex items-center flex-col md:flex-row">
                  {god.currentNetwork.account ? (
                    <div className="flex items-center mb-2 md:mb-0">
                      <img className="w-5 md:w-8 mr-2 md:mr-4" src={addressLogo} alt="" />
                      <span className="text-sm md:text-xl xl:text-2xl">
                        {god.currentNetwork.account.slice(0, 6)}...{god.currentNetwork.account.slice(-4)}
                      </span>
                    </div>
                  ) : (
                    <div className="simple-button text-center font-thin px-3" style={{ marginTop: 0, lineHeight: "32px" }} onClick={store.onConnectWallet}>
                      {lang.t("connect")}
                    </div>
                  )}
                </div>
              </div>

              {/* table head */}
              <div className="bd-aridrip_thead flex justify-between items-center px-2 py-4 md:px-5 md:py-6 text-lg">
                <div className="w-1/5">{lang.t("asset")}</div>
                <div className="flex  justify-end items-center" style={{ width: "30%" }}>
                  {lang.t("airdrip.status")}
                  {/* <img src={sortIcon} className="w-5 h-5 ml-2" alt="" /> */}
                </div>
                {/* <div className="flex w-1/5 justify-end items-center"> */}
                {/* {lang.t("drip.per.block")} */}
                {/* <img src={sortIcon} className="w-5 h-5 ml-2" alt="" /> */}
                {/* </div> */}
                <div className="flex w-1/4 justify-end items-center text-right">
                  {lang.t("redeem.with")}
                  {/* <img src={sortIcon} className="w-5 h-5 ml-2" alt="" /> */}
                </div>
              </div>

              {drip.config.list.map((item, index) => {
                return (
                  <div key={item.address} className="flex flex-col" style={{ background: item.isShow ? "rgba(251, 31, 184, 0.05)" : "transparent" }}>
                    {/* onClick={() => item.isActive && store.toggleShow(item, index)} */}
                    <section className="flex justify-between  px-2 py-4 md:px-5 md:py-4 text-lg">
                      <div className="flex items-center items-center w-1/5">
                        <TokenAvatar src={item.tokenIcon} defaultImg={require("../../static/images/icon/token_custom.png")} />
                        <span className="c-cd5 text-xs md:text-lg">{item.tokenSymbol}</span>
                      </div>
                      <div className="flex items-center  justify-end text-xs md:text-md text-right" style={{ width: "30%" }}>
                        Ends ......
                        {/* {item.isActive ? (item.pool ? `${lang.t("airdrip.ends.at")} ${drip.getDateByHeight({ height: item.pool.endBlock })}` : "...") : lang.t("airdrip.coming.soon")} */}
                      </div>
                      {/* <div className="flex items-center w-1/5 justify-end text-xs md:text-md text-right">
                        {utils.helper.number.toPrecisionFloor(item.pool.dripPerBlock.format)} {item.symbol}
                      </div> */}
                      <div className="flex items-center w-1/4 justify-end text-xs md:text-md text-right">
                        ...{/* {item.pool.exchangeAmount.format} ({item.tokenSymbol}) <img className="w-3 md:w-5 h-3 md:h-5 ml-1 md:ml-2" src={item.isShow ? upArrow : downArrow} alt="" /> */}
                      </div>
                    </section>
                    {item.isShow && (
                      <section className="bd-aridrip_itemBody flex justify-between flex-col lg:flex-row  px-8 md:px-20 lg:px-10 xl:px-20 py-4 md:py-8 lg:py-6 xl:py-12 text-lg">
                        <div className="flex flex-col flex-1 mb-6 lg:mb-0 lg:pr-6">
                          <section className="flex items-start md:items-center flex-col md:flex-row ">
                            <div className="text-sm md:text-base">
                              {item.tokenSymbol}&nbsp;&nbsp;{lang.t("airdrip")}
                            </div>
                            {/* <input className="bd-aridrip_input flex-1 bd-aridrip_readOnly flex-1 w-full" readOnly type="text" value={item.address} /> */}
                          </section>
                          {god.currentNetwork.account ? (
                            <>
                              <section className="flex items-start md:items-center  mt-5 flex-col md:flex-row">
                                <div style={{ width: "100px" }} className="flex items-center text-sm md:text-base">
                                  {lang.t("points")}
                                  <Tooltip placement="right" color="rgba(255,255,255,0.9)" title={lang.t("points.tips")}>
                                    <img src={exclamation} alt="" className="w-4 ml-1 inline-block" />
                                  </Tooltip>
                                </div>
                                {item.pool.ended ? (
                                  <div className="flex-1 w-full relative">
                                    <InputNumber style={{ width: "100%", background: "rgba(0,0,0,0.15)", border: "none" }} readOnly value={1} />
                                  </div>
                                ) : (
                                  <div className="flex-1 w-full relative">
                                    <InputNumber
                                      style={{ width: "100%" }}
                                      type="number"
                                      min={0}
                                      max={drip.config.account.value.toNumber()}
                                      value={item.pool.points.value}
                                      onChange={(value) => store.setPointsValue(value, item)}
                                    />
                                    <div
                                      className={`
                                        ${!item.pool.isRedeem ? "c-cd5" : "c-disabled"} 
                                        absolute right-0 top-0 pr-2 text-sm md:text-base pt-1 cursor-pointer
                                      `}
                                      onClick={() => (!item.pool.isRedeem ? store.setPointsMax(item) : "")}
                                    >
                                      {lang.t("max")}
                                    </div>
                                  </div>
                                )}
                              </section>
                              <section className="flex items-start md:items-center mt-5 flex-col md:flex-row">
                                <div style={{ width: "100px" }} className="text-sm md:text-base">
                                  {lang.t("amount")}
                                </div>
                                {item.pool.ended ? (
                                  <div className="flex-1 w-full relative">
                                    <InputNumber style={{ width: "100%", background: "rgba(0,0,0,0.15)", border: "none" }} readOnly value={item.pool.exchangeAmount.format} />
                                  </div>
                                ) : (
                                  <div className="flex-1 w-full relative">
                                    <InputNumber
                                      style={{ width: "100%" }}
                                      min={0}
                                      max={item.pool.volume.format}
                                      value={Number(item.pool.amount.format)}
                                      onChange={(value) => store.setAmountValue(value, item)}
                                    />
                                    <div
                                      className={`
                                        ${!item.pool.isRedeem ? "c-cd5" : "c-disabled"} 
                                        absolute right-0 top-0 pr-2 text-sm md:text-base pt-1 cursor-pointer
                                      `}
                                      onClick={() => (!item.pool.isRedeem ? store.setAmountMax(item) : "")}
                                    >
                                      {lang.t("max")}
                                    </div>
                                  </div>
                                )}
                              </section>
                              <section className="flex items-start md:items-center  mt-2 flex-col md:flex-row">
                                <div style={{ width: "100px" }} className="text-sm md:text-base"></div>
                                <div className="text-xs  w-full md:w-auto" style={{ color: "rgba(255, 255, 255, 0.3)" }}>
                                  1 Point per {item.pool.exchangeActuallyAmount.format} {item.tokenSymbol}
                                </div>
                              </section>
                              <section className="flex items-start md:items-center flex-col md:flex-row  mt-2">
                                <div style={{ width: "100px" }}></div>
                                <div
                                  className={`
                                    ${!item.pool.isRedeem && item.pool.amountStatus && item.pool.pointStatus ? "c-cd5" : "c-disabled"} 
                                    ${item.pool.isRedeem ? "loadingBtn cursor-not-allowed" : "cursor-pointer"}
                                    confirm-button flex-1 text-center font-thin  w-full leading-2
                                `}
                                  onClick={() => (!item.pool.isRedeem && item.pool.amountStatus && item.pool.pointStatus ? store.redeem(index) : "")}
                                >
                                  {item.pool.pointStatus ? lang.t("redeem") : lang.t("insufficient.balance")}
                                </div>
                              </section>
                            </>
                          ) : (
                            <section className="flex items-start md:items-center flex-col md:flex-row  mt-2">
                              <div style={{ width: "100px" }}></div>
                              <div className="confirm-button flex-1 text-center font-thin  w-full leading-2 " style={{ marginTop: 0 }} onClick={store.onConnectWallet}>
                                {lang.t("connect")}
                              </div>
                            </section>
                          )}
                        </div>
                        <div className="flex flex-col flex-1 lg:pl-4 text-xs md:text-sm pt-3 border-t border-solid border-gray-600 md:border-0">
                          <section className="flex items-center justify-between">
                            <div className="flex items-center">
                              <span className="flex-none">{lang.t("token.name")}:&nbsp;</span>
                              <span>
                                <a className="website flex-1 break-all c-cd5 truncate" target="_blank" href={`${publicConfig.IOTEX_SCAN_IO}${item.address}`}>
                                  {item.tokenName} ({item.tokenSymbol})
                                </a>
                                {item.isMineAdd && (
                                  <span>
                                    &nbsp;
                                    <span className="c-cd5 cursor-pointer" onClick={() => store.onRemoveToken(item.address)}>
                                      (Remove)
                                    </span>
                                  </span>
                                )}
                              </span>
                            </div>
                            {/* <span>$1900</span> */}
                          </section>
                          <section className="flex items-center mt-4 md:mt-6">
                            <span className="flex-none">{lang.t("airdrip.pool.size")}:&nbsp;</span>
                            <div className="truncate">
                              {item.pool.volume.format}
                              {item.tokenSymbol}
                            </div>
                          </section>
                          <section className="flex items-center mt-4 md:mt-6">
                            <span className="flex-none">{lang.t("balance")}:&nbsp;</span>
                            <div className="truncate">{item.balance.format}</div>
                          </section>
                        </div>
                      </section>
                    )}
                  </div>
                );
              })}

              {/* {god.currentNetwork.account && (
                
              )} */}
            </div>
            {/* @jane uncomment after fixing bug
            <div className="mt-2 md:mt-0 simple-button text-center px-3 cursor-pointer w-full" style={{ marginTop: 0, lineHeight: "70px", width: "100%" }} onClick={store.onAddPool}>
              + {lang.t("add")}
            </div>
            */}
          </div>
        </article>

        <div className="bd-airdrip_footer text-center text-xs md:text-base font-thin leading-normal tracking-wide py-12">
          {lang.t("bottom-tips")}
          {/* {lang.t("interested.tips")} */}
          {/* <a href="/provide-airdrip" className="c-cd5 ml-2 font-normal">
            {lang.t("check.here")} &gt;&gt;
          </a> */}
        </div>
        <ConnectDialog isModalVisible={store.isModalVisible} handleOk={() => (store.isModalVisible = false)} handleCancel={() => (store.isModalVisible = false)} />
        <AddPoolDialog isModalVisible={store.isAddModalVisible} handleOk={store.onAddToken} handleCancel={() => (store.isAddModalVisible = false)} />
      </div>
    </>
  ));
});
