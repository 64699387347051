import { makeAutoObservable } from "mobx";
import { AirdripConfig } from "../../../configs/AirdripConfig";
import { _ } from "../utils/lodash";
import { RootStore } from "./Root";

export class HomeStore {
  rootStore: RootStore;
  airdrip = AirdripConfig;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.airdrip.network = this.god.currentNetwork;
    this.airdrip.rootStore = this.rootStore;
    makeAutoObservable(this);
  }

  get god() {
    return this.rootStore.god;
  }

  async loadPublichData() {
    // TODO: dev
    return;
    await this.god.currentNetwork.multicall([
      this.airdrip.preMulticall({
        method: "getCurrentRound",
        handler: (v) => {
          this.airdrip.currentRoud.setValue(v.toNumber());
        },
      }),
    ]);
  }

  async loadPrivateData() {
    await this.god.currentNetwork.multicall([
      ...this.airdrip.round
        .filter((i) => i.valid)
        .map((round) =>
          this.airdrip.preMulticall({
            method: "checkRegisteredRound",
            params: [round.id, this.god.currentNetwork.account],
            handler: (v) => {
              console.log(v.toString());
              round.isSign = v.toString() == "true";
            },
          })
        ),
    ]);
  }
}
