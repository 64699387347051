import React, { useEffect, useState } from "react";
import "./index.scss";

interface IComponentProps {
  timezone: number;
  destTime: number;
}

interface IComponentState {
  hours: number;
  minutes: number;
  seconds: number;
}

const toTwoLetter = (num: number) => `${num < 10 ? "0" : ""}${num}`;

const getHMS = (props: IComponentProps) => {
  const date = new Date();
  const destDate = new Date(date);

  destDate.setHours(props.destTime);
  destDate.setMinutes(0);
  destDate.setSeconds(0);
  destDate.setMinutes(destDate.getMinutes() - destDate.getTimezoneOffset() - 60 * props.timezone);

  if (destDate < date) {
    destDate.setDate(destDate.getDate() + 1);
  }

  let diffTime = Math.abs(destDate.getTime() - date.getTime()) / 1000;
  const sec = Math.floor(diffTime % 60);
  diffTime = Math.floor(diffTime / 60);
  const min = Math.floor(diffTime % 60);
  diffTime = Math.floor(diffTime / 60);
  return { hours: diffTime, minutes: min, seconds: sec };
};

export const CountdownTimer = (props: IComponentProps) => {
  const { timezone, destTime } = props;
  const [state, setState] = useState<IComponentState>(getHMS(props));

  useEffect(() => {
    const timer = setInterval(() => {
      setState(getHMS(props));
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <span>
      {toTwoLetter(state.hours)}h : {toTwoLetter(state.minutes)}m : {toTwoLetter(state.seconds)}s
    </span>
  );
};
