import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { IotexMulticall } from "../../../common/store/lib/multicall";
import { Contract } from "iotex-antenna/lib/contract/contract";
import { useStore } from "../../../common/store/index";
import multicallABI from "./IotexMulticall.json";
import { utils } from "../../../common/utils/index";

export const IotexProvider = observer(() => {
  const { god } = useStore();
  useEffect(() => {
    god.setNetwork("iotex");
    const antenna = god.iotex.getAntenna();
    god.iotex.multiCall = new IotexMulticall({
      contract: new Contract(multicallABI, god.currentChain.info.multicallAddr, { provider: antenna.iotx }),
    });

    // god.loadPublichData();
    if (god.iotex.account) {
      god.loadBalance();
      god.setShowConnecter(false);
    }
  }, [god.iotex.account]);

  useEffect(() => {
    if (utils.env.isMobile()) {
      god.iotex.activeConnector();
    }
    const provider = localStorage.getItem("latestIotexProvider");
    if (provider) {
      god.iotex.activeConnector();
    }
  }, []);

  return <div />;
});
