import remotedev from "mobx-remotedev";
import { observable, action, makeAutoObservable } from "mobx";
import { rpcClient } from "../../client/utils/rpc";
import { statistics, daily_statistics, schedules } from "@generated/burndropService";
import { utils } from "../utils/index";

export class BurnDropStore {
  LatestStats: Partial<statistics> = {};
  LatestSchedules: Partial<schedules> = {};
  stats: Partial<schedules[]> = [];
  states: { [key: string]: string } = {};
  isLoadingStats = false;
  hasMore = true;
  account = "";
  constructor() {
    makeAutoObservable(this);
  }

  async loadLatestStats() {
    const [stats, states, config] = await Promise.all([rpcClient.getLatestStats(), rpcClient.getKVStates(), rpcClient.getLatestBurn()]);
    console.log("loadStats", config);
    this.LatestStats = stats;
    this.states = { ...states };
  }

  async loadStats() {
    if (this.isLoadingStats) return;
    this.isLoadingStats = true;
    const [err, res] = await utils.helper.promise.runAsync(rpcClient.getSchedules({ args: { skip: this.stats.length, orderBy: { created_at: "desc" }, take: 10 } }));
    if (err) {
      return console.log(err);
    }
    this.isLoadingStats = false;
    this.hasMore = res?.hasMore;
    if (res.data.length) {
      this.stats = [...this.stats, ...res.data];
    }
  }
}
