import React, { useState } from "react";
import { Button, Dropdown, Menu } from "antd";
import { CaretDownOutlined } from "@ant-design/icons";
import { useStore } from "../../../common/store/index";
import { Link, NavLink } from "react-router-dom";
import "./index.scss";

const IotexLogo = require("../../static/images/logoIotex.png");
const MenueIcon = require("../../static/images/menue.png");
const iopay = require("../../static/images/icon_iopay.png");
const mimo = require("../../static/images/icon_mimo_black.png");

export const Header = () => {
  const { lang } = useStore();
  const [langText, setLanguage] = useState(lang.t("english"));

  const menu = (
    <Menu className="menu__item">
      <Menu.Item>
        <Link className="mobile__anchor" to="/history">
          {lang.t("history")}
        </Link>
      </Menu.Item>
      <Menu.Item>
        <Link className="mobile__anchor" to="/about">
          {lang.t("about")}
        </Link>
      </Menu.Item>
      <Menu.Item>
        <Link className="mobile__anchor" to="/get-airdrip">
          {lang.t("get.airdrip")}
        </Link>
      </Menu.Item>
      <Menu.Item>
        <a className="mobile__anchor" href="https://mimo.exchange/" target="_blank">
          <img src={mimo} alt="" />
          {lang.t("mimo")}
        </a>
      </Menu.Item>
      <Menu.Item>
        <a className="mobile__anchor" href="https://iopay.iotex.io/" target="_blank">
          <img src={iopay} alt="" />
          {lang.t("ioPay")}
        </a>
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          setLanguage(lang.t("english"));
          lang.setLang("en");
        }}
      >
        <a>{lang.t("english")}</a>
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          setLanguage(lang.t("chinese"));
          lang.setLang("zh_CN");
        }}
      >
        <a>{lang.t("chinese")}</a>
      </Menu.Item>
    </Menu>
  );

  const languageMenu = (
    <Menu>
      <Menu.Item
        onClick={() => {
          setLanguage(lang.t("english"));
          lang.setLang("en");
        }}
      >
        {lang.t("english")}
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          setLanguage(lang.t("chinese"));
          lang.setLang("zh_CN");
        }}
      >
        {lang.t("chinese")}
      </Menu.Item>
    </Menu>
  );
  return (
    <div className="header__main flex items-center justify-between px-4 md:px-12 lg:px-12 xl:px-24 py-2 h-16">
      <div className="header__main__screen__logo">
        <Link className="mobile__anchor" to="/">
          <img src={IotexLogo} />
        </Link>
      </div>
      <ul className="header__main__screen__logo__nav hidden md:flex items-center mb-0">
        <li className="mr-8">
          <Link to="/history">{lang.t("history")}</Link>
        </li>
        <li className="mr-8">
          <Link to="/about">{lang.t("about")}</Link>
        </li>
        <li className="mr-8">
          <Link to="/get-airdrip">{lang.t("get.airdrip")}</Link>
        </li>
        <li className="mr-8">
          <a href="https://mimo.exchange/" target="_blank">
            <img src={mimo} alt="" />
            {lang.t("mimo")}
          </a>
        </li>
        <li className="mr-8">
          <a href="https://iopay.iotex.io/" target="_blank">
            <img src={iopay} alt="" />
            {lang.t("ioPay")}
          </a>
        </li>
        <li>
          <Dropdown overlay={languageMenu} arrow placement="bottomCenter">
            <a className="language-menu flex items-center" onClick={(e) => e.preventDefault()}>
              {langText} <CaretDownOutlined style={{ color: "#ffff", marginLeft: 5 }} />
            </a>
          </Dropdown>
        </li>
      </ul>
      <div className="block md:hidden">
        <Dropdown className="dropdown__content p-0 border-0" trigger={["click"]} overlay={menu} placement="bottomLeft" getPopupContainer={() => document.querySelector(".dropdown__content")}>
          <Button icon={<img src={MenueIcon} width="48" height="48" />}></Button>
        </Dropdown>
      </div>
    </div>
  );
};
