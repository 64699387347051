import { makeAutoObservable } from "mobx";
import AirdripAbi from "../../constants/airdrip.json";
import { LoadingState } from "./LoadingState";
import { CallParams } from "./type";
import { NetworkState } from "./NetworkState";
import { RootStore } from "../Root";
import { _ } from "../../utils/lodash";
import { BigNumberState } from "../type";

export class RoundState {
  id: number;
  name: string;
  range: string;
  amount: number | string = 0;
  symbol: string = "";
  valid: boolean = false;
  isSign: boolean = false;
  airdrip: AirDripState;
  constructor(args: Partial<RoundState>) {
    Object.assign(this, args);
    makeAutoObservable(this);
  }
  get stakedAmount() {
    const amount = _.get(this.airdrip.rootStore.burnDrop, `states.AirdripTotalStaked_${this.id}`, null);
    return amount ? new BigNumberState({ value: amount }) : new BigNumberState({});
  }
  get icon() {
    if (!this.symbol) return;
    return this.symbol.split("-")[0];
  }
  get isFinished() {
    // return this.airdrip.currentRoud.value > this.id;
    return true;
  }
}

export class AirDripState {
  address: string;
  beingDropped: number;
  abi = AirdripAbi;
  round: RoundState[];
  currentRoud = new LoadingState({});
  network: NetworkState;
  rootStore: RootStore;
  constructor(args: Partial<AirDripState>) {
    Object.assign(this, args);
    this.round.forEach((i) => {
      i.airdrip = this;
    });
    makeAutoObservable(this);
  }

  get totalAirdripStaked() {
    const round = this.round.find((i) => this.currentRoud.value == i.id);
    return round.stakedAmount;
  }

  register(item: RoundState) {
    console.log(item.id, this.currentRoud.value);
    if (item.id == this.currentRoud.value) {
      return this.network.execContract(Object.assign({ address: this.address, abi: this.abi, method: "register" }));
    }
    return this.network.execContract(Object.assign({ address: this.address, abi: this.abi, method: "registerNextRound" }));
  }

  preMulticall(args: Partial<CallParams>) {
    return Object.assign({ address: this.address, abi: this.abi }, args);
  }
}
