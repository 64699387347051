import { makeAutoObservable } from "mobx";
import { helper } from "../../utils/helper";

export class StorageState<T> {
  key: string;
  value: T | any;
  default: T | any;
  constructor(args: Partial<StorageState<T>>) {
    Object.assign(this, args);
    makeAutoObservable(this);
    this.load();
  }

  load() {
    const value = localStorage.getItem(this.key);
    this.value = helper.json.safeParse(value);
    if (this.value == null) {
      this.value = this.default;
    }
  }

  save(value: any) {
    this.value = value;
    localStorage.setItem(this.key, JSON.stringify(value));
  }

  clear() {
    localStorage.removeItem(this.key);
  }
}
