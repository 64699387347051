import { eventBus } from "../../common/utils/eventBus";
import { rootStore } from "../../common/store/index";

export const hooks = {
  async waitAccount(timeout?) {
    return new Promise<void>((res, rej) => {
      if (rootStore.god.currentNetwork.account) {
        res();
      } else {
        eventBus.once("client.wallet.onAccount", () => {
          res();
        });
        if (timeout) {
          setTimeout(() => {
            rej();
          }, timeout);
        }
      }
    });
  },
};
