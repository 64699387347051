import { DripState, DripTokenState } from "../src/common/store/lib/DripState";
import { IotexNetworkConfig } from "./NetworkConfig";

export const TestNetDripTokenConfig = new DripState({
  cacheKey: "TestNetDripTokenConfig",
  address: "io1g062wvxfdug4avjk6v6gjx95tevnkc7aet7d6v",
  network: IotexNetworkConfig,
  list: [
    new DripTokenState({
      address: "io1eqcg8j5ce8tuq0q3fgdrtyxwv72vv0wz0dyeyz",
      isShow: false,
      valid: true,
      website: "http://cyclone.xyz",
    }),
    // new DripTokenState({
    //   address: "io140zpcqed6n08g6f7q9ergdka20h5p5tr2x6plx",
    //   isShow: false,
    //   valid: true,
    // }),
  ],
});

export const MainNetDripTokenConfig = new DripState({
  cacheKey: "MainNetDripTokenConfig",
  // address: "io1mm094fht5s77yn9wmp4u4lyxwaf7235ywrzcxj",
  address: "io1azlhgz07z0h79kmvjgkq0ye8eavhwduggsqpkf",
  network: IotexNetworkConfig,
  list: [
    new DripTokenState({
      address: "io12w7agqdgwx7slp8fgcv7mnqvy3yf6j4tz0fnms",
      presetSymbol: "GFT-WIOTX",
      presetIcon: "https://githubproxy.b-cdn.net/iotexproject/iotex-token-metadata/master/images/io1zl0el07pek4sly8dmscccnm0etd8xr8j02t4y7.png",
      isActive: true,
      isShow: false,
      valid: true,
      website: "https://starcrazy.com/",
    }),
  ],
});

// const data = [
//   new DripTokenState({
//     address: "io1f4acssp65t6s90egjkzpvrdsrjjyysnvxgqjrh",
//     decimals: 18,
//     symbol: "CYC",
//     tokenName: "Cyclone Protocol",
//     isShow: false,
//     valid: true,
//   }),
//   new DripTokenState({
//     address: "io1hp6y4eqr90j7tmul4w2wa8pm7wx462hq0mg4tw",
//     symbol: "VITA",
//     decimals: 18,
//     isShow: false,
//     valid: true,
//     tokenName: "Vitality",
//   }),
//   new DripTokenState({
//     address: "io13z9y0kqcgsmke00xwdalcup2zz3pwhuhexd22e",
//     symbol: "SDI",
//     decimals: 18,
//     isShow: false,
//     valid: true,
//     tokenName: "SonicDiamond",
//   }),
//   new DripTokenState({
//     address: "io1zl0el07pek4sly8dmscccnm0etd8xr8j02t4y7",
//     symbol: "GFT",
//     decimals: 18,
//     isShow: false,
//     valid: true,
//     tokenName: "GameFantasyToken",
//   }),
//   new DripTokenState({
//     address: "io1j9u0fmy20lm0uz8gfrh2c0wmuxjl43cdmgva4a",
//     symbol: "UP",
//     decimals: 18,
//     isShow: false,
//     valid: true,
//     tokenName: "UP",
//   }),
// ]
