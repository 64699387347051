import React, { useEffect } from "react";
import { Modal } from "antd";
import { useStore } from "../../../common/store/index";
import { useLocalStore } from "mobx-react-lite";
import "./index.scss";
import { DripTokenState } from "../../../common/store/lib/DripState";
import { IotexNetworkConfig } from "../../../../configs/NetworkConfig";
import { validateAddress } from "iotex-antenna/lib/account/utils";
import { observer } from "mobx-react";

interface IComponentProps {
  isModalVisible?: boolean;
  handleOk?: any;
  handleCancel?: any;
}

export const AddPoolDialog = observer((props: IComponentProps) => {
  const { lang } = useStore();
  const { isModalVisible, handleOk, handleCancel } = props;

  const store = useLocalStore(() => ({
    asset: "",
    newToken: new DripTokenState({
      network: IotexNetworkConfig,
      isShow: false,
      valid: true,
      address: "",
      symbol: "",
      decimals: 0,
    }),
    name: "",
    async getAssetDetail(value) {
      console.log(value);
      if (validateAddress(value)) {
        store.newToken.address = value;
        await store.newToken.loadTokenData();
        console.log("newToken===>", store.newToken);
      }
    },
    onOK() {
      props.handleOk(store.newToken.address);
    },
  }));

  return (
    <article>
      <Modal wrapClassName="connectDialog" title={lang.t("add.asset")} visible={isModalVisible} onOk={handleOk} onCancel={props.handleCancel} footer={null}>
        <input className="bd-aridrip_input flex-1 flex-1 w-full" type="text" onChange={(e) => store.getAssetDetail(e.target.value)} />
        {store.newToken.tokenName && <div className="mt-4">Token Name: {store.newToken.tokenName}</div>}
        <div
          className={`
            ${validateAddress(store.newToken.address) ? "c-cd5" : "c-disabled"}
            simple-button text-center
          `}
          onClick={() => (validateAddress(store.newToken.address) ? store.onOK() : "")}
        >
          {lang.t("ok")}
        </div>
      </Modal>
    </article>
  );
});
