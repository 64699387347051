import React, { useEffect } from "react";
import { Row, Col, Tooltip } from "antd";
import "./index.scss";
import { StackCoinAvatar } from "../../components/StackCoinAvatar";
import { StateItem } from "../../components/StateItem";
import { useStore } from "../../../common/store/index";
import { useObserver, useLocalStore } from "mobx-react-lite";
import { Header } from "../../components/Header";
import { CountdownTimer } from "../../components/CountdownTimer";
import { fromRau } from "iotex-antenna/lib/account/utils";
import { numberWithCommas } from "../../utils/index";
import { observer } from "mobx-react";
import { ConnectDialog } from "../../components/ConnectDialog";
import { RoundState } from "../../../common/store/lib/AirdripState";
import { IotexProvider } from "../../components/IotexProvider";
import BigNumber from "bignumber.js";

const questionCard = require("../../static/images/icon_card.png");
const BURN_DROP_IGNITE = require("../../static/images/burnDropNft.png");
const exclamation = require("../../static/images/exclamation.png");
const frame = require("../../static/images/frame.png");

export const Home = observer(() => {
  const { lang, god, home, drip } = useStore();
  const { burnDrop } = useStore();
  const store = useLocalStore(() => ({
    isModalVisible: false,
    brundropAmount: 0,
    get TotalDroppedIOTX() {
      // return moment().diff(moment("2020-11-17"), "days") * 480000;
      return Number(fromRau(burnDrop.states.TotalDroppedIOTX, "iotex")) || 0;
    },
    get CurrentYield() {
      return new BigNumber(burnDrop.states.CurrentYield || 0).multipliedBy(100).plus(7).toFixed(2);
    },
    get DripAPY() {
      return new BigNumber(burnDrop.states.AirdripAPY || 0).toFixed(2);
    },
    get TotalDroppedDevices() {
      return Number(burnDrop.states.TotalDroppedDevices) || 0;
    },
    get TotalStakedAmount() {
      return Number(burnDrop.LatestStats.total_staked_amount) || 0;
    },
    onConnectWallet() {
      store.isModalVisible = true;
      god.iotex.activeConnector();
    },
    async registerRound(item: RoundState) {
      const res = await home.airdrip.register(item);
      if (res) {
        // TODO show loading
        const receipt = await res.wait();
        item.isSign = true;
        console.log(receipt);
      }
    },
    get BucketAmount() {
      return Number(burnDrop.LatestStats.bucket_amount) || 0;
    },
    getTotalBurntIOTX: async () => {
      try {
        const response = await fetch("https://nsv3-hasura.iotex.io/api/rest/kv");
        const data = await response.json();
        let config = {};
        data.KV.forEach((item) => {
          if (item.key === "burndrop_amount") {
            config[item.key] = item.value;
            store.brundropAmount = Number(item.value);
            return false;
          }
        });
        console.log("getTotalBurntIOTX", config);
      } catch (e) {
        console.log("e", e);
      }
    },
  }));

  useEffect(() => {
    home.loadPublichData();
    store.getTotalBurntIOTX();
    burnDrop.loadLatestStats();
    const intervalId = setInterval(() => {
      burnDrop.loadLatestStats();
    }, 60000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    if (god.currentNetwork.account) {
      home.loadPrivateData();
    }
  }, [god.currentNetwork.account]);

  const renderAccount = useObserver(() => (item: RoundState) => {
    if (god.currentNetwork.account) {
      if (item.isFinished) {
        return (
          <div className="w-full simple-button" style={{ color: "#6f6d72" }}>
            {lang.t("finished")}
          </div>
        );
      }
      if (item.isSign) {
        return (
          <div className="w-full simple-button" style={{ color: "#6f6d72" }}>
            {lang.t("signed.up")}
          </div>
        );
      }

      return (
        <div className="w-full simple-button" onClick={() => store.registerRound(item)}>
          {lang.t("signup.now")}
        </div>
      );
    }
    return (
      <div className="w-full simple-button" onClick={store.onConnectWallet}>
        {lang.t("signup.now")}
      </div>
    );
  });

  console.log("brundropAmount", store.brundropAmount);

  return (
    <div className="page__home flex flex-col  text-center">
      <Header />
      <IotexProvider />
      <div className="page__home__texts_main flex-1">
        <div className="screen h-full">
          <article className="h-auto lg:h-full contentBox">
            <img src={BURN_DROP_IGNITE} className="page__home__logo mx-auto" />
            <div className="page__home__img__burn_drop_ignite mb-1 c-white font-light px-8 text-xl xl:text-2xl leading-normal tracking-wider">{lang.t("title")}</div>
            <div className="page__home__img__burn_drop_ignite__subtitle c-aqua-marine">
              <a href="https://iott.network/" className="c-aqua-marine" target="_blank" rel="noopener noreferrer">
                {lang.t("sub.title")} &gt;&gt;
              </a>
            </div>
            <div className="page__home__bottom text-center">
              <div className="page__home__bottom__stats">
                <div className="screen">
                  <Row gutter={6} className="page__home__bottom__stats__row font-thin">
                    <Col xs={24} lg={8}>
                      <StateItem tips={<div className="text-xs font-light mt-1 mb-1"></div>} unit="" icon={require("../../static/images/icon_burnt.png")} value={store.brundropAmount} />
                    </Col>
                    {/* value={store.TotalDroppedIOTX} */}
                    <Col xs={24} lg={8}>
                      <StateItem
                        tips={<div className="text-center text-xs font-light mt-1 mb-1 flex items-center justify-center"></div>}
                        unit=""
                        icon={require("../../static/images/icon_dropped.png")}
                        value={store.TotalDroppedIOTX}
                        label={
                          <>
                            <div>
                              {lang.t("apy.tips2", { amount: numberWithCommas(store.CurrentYield) })}
                              <Tooltip placement="right" color="rgba(255,255,255,0.9)" title={lang.t("annul.tips")}>
                                <img src={exclamation} alt="" className="w-4 ml-1 inline-block" />
                              </Tooltip>
                            </div>
                          </>
                        }
                      />
                    </Col>
                    <Col xs={24} lg={8}>
                      <StateItem
                        tips={
                          <div className="text-center text-sm font-semibold mt-1 mb-1 flex items-center justify-center" style={{ color: "#F7931A" }}>
                            <span style={{ whiteSpace: "nowrap" }}>{lang.t("airdrip.gft.tips")}</span>
                            {/* <Tooltip
                        placement="right"
                        color="rgba(255,255,255,0.9)"
                        title={
                          <a href="https://iotex.medium.com/burn-drop-tokenomics-v2-xrc20-airdrips-boosted-iotx-drops-157180550850" target="_blank" className="text-gray-800 underline">
                            {lang.t("apy.tips1")}
                          </a>
                        }
                      >
                        <img src={exclamation} alt="" className="w-4 ml-1 inline-block" />
                      </Tooltip> */}
                          </div>
                        }
                        unit="$"
                        icon={require("../../static/images/icon_dropped_gft.png")}
                        value={200000}
                        label={
                          <>
                            <button
                              className="mx-auto block disabledBtn"
                              disabled
                              style={{
                                width: "200px",
                                lineHeight: "40px",
                                textAlign: "center",
                                fontSize: "1.25rem",
                                color: "rgba(3, 255, 225, 0.8)",
                                border: "1px solid #6f6d72",
                                marginTop: "12px",
                              }}
                            >
                              {lang.t("get.airdrip")}
                              {/* {lang.t("apy.tips2", { amount: store.DripAPY })}
                        <Tooltip placement="right" color="rgba(255,255,255,0.9)" title={lang.t("dirp.apy.tips")}>
                          <img src={exclamation} alt="" className="w-4 ml-1 inline-block" />
                        </Tooltip> */}
                            </button>
                            {/* <div className="text-sm mt-1">{lang.t("apy.tips3", { amount: numberWithCommas(home.airdrip.totalAirdripStaked.format) })}</div> */}
                          </>
                        }
                      />
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
            <div className="inline-block page__home__btn__stake_now">
              <a
                className="c-white font-light
                    text-sm lg:text-lg xl:text-xl
                    leading-snug block"
                href="https://stake.iotex.io/"
              >
                {lang.t("stake.now")}
              </a>
            </div>
            <div className="page__home__bottom_tips c-white text-xs font-thin leading-normal tracking-wide px-8 lg:px-0 mb-4 md:mb-0">
              {lang.t("stake.now.tips")} <CountdownTimer timezone={-7} destTime={18} />.
              {/* <a className="c-aqua-marine ml-2 font-normal" href="https://member.iotex.io/">
                {lang.t("stake.now")} &gt;&gt;
              </a> */}
            </div>
          </article>
          {/* <div className="w-full h-auto lg:h-full contentBox " id="airdrip">
            <div className="page__airdrip__img__burn_drop_ignite mt-12 lg:mt-12 xl:mt-16 mb-4 c-white font-normal px-8 text-xl xl:text-3xl leading-normal tracking-wider">
              {lang.t("airdrip.title")}
            </div>
            <div className=" c-white text-md xl:text-lg mx-auto font-thin" style={{ maxWidth: "65%" }}>
              {lang.t("airdrip.content")}
            </div>
            <div className="flex items-center justify-between mx-auto mt-6 flex-col lg:flex-row" style={{ maxWidth: "808px" }}>
              {home.airdrip.round.slice(0, 2).map((item) => {
                return (
                  <div key={item.id} className="home-week-card c-white mb-12 lg:mb-0">
                    <div className="text-xl font-semibold mb-1">{item.name}</div>
                    <div className="text-base font-thin mb-5">{item.range}</div>
                    <div className="text-sm mb-1" style={{ color: "#F7931A" }}>
                      {item.amount ? numberWithCommas(item.amount) : "???"} IOTX Worth of
                    </div>
                    <div className="flex justify-center">
                      <StackCoinAvatar first={`/img/${item.icon.toUpperCase()}.png`} second={"/img/IOTX.png"} />
                      <span className="text-2xl ml-3" style={{ lineHeight: "36px" }}>
                        {item.symbol} LP
                      </span>
                    </div>
                    <div className="text-sm mt-5">{lang.t("signup.tips", { amount: item.stakedAmount ? numberWithCommas(item.stakedAmount.format) : "???" })} </div>
                    {renderAccount(item)}
                  </div>
                );
              })}
            </div>
            <div className="flex items-center justify-between mx-auto mt-6 flex-col lg:flex-row" style={{ maxWidth: "1240px" }}>
              {home.airdrip.round.slice(2, 5).map((item) => {
                if (item.valid) {
                  return (
                    <div key={item.id} className="home-week-card c-white mb-12 lg:mb-0">
                      <div className="text-xl font-semibold mb-1">{item.name}</div>
                      <div className="text-base font-thin mb-5">{item.range}</div>
                      <div className="text-sm mb-1" style={{ color: "#F7931A" }}>
                        {item.amount ? numberWithCommas(item.amount) : "???"} IOTX Worth of
                      </div>
                      <div className="flex justify-center">
                        <StackCoinAvatar first={`/img/${item.icon.toUpperCase()}.png`} second={"/img/IOTX.png"} />
                        <span className="text-2xl ml-3" style={{ lineHeight: "36px" }}>
                          {item.symbol} LP
                        </span>
                      </div>
                      <div className="text-sm mt-5">{lang.t("signup.tips", { amount: item.stakedAmount ? numberWithCommas(item.stakedAmount.format) : "???" })} </div>
                      {renderAccount(item)}
                    </div>
                  );
                } else {
                  return (
                    <div key={item.id} className="home-week-card-none c-white mb-12 lg:mb-0">
                      <div className="text-xl font-semibold mb-1">{item.name}</div>
                      <div className="text-base font-thin mb-5">{item.range}</div>
                      <img src={questionCard} alt="" />
                    </div>
                  );
                }
              })}
            </div>
          </div> */}
          {/* phase */}
          <div className="phase absolute left-0 top-auto pl-4 md:pl-12 lg:pl-12 xl:pl-24">
            <div className="relative">
              <img src={frame} className="phase-img" alt="" />
              <div className="absolute text-center text-base font-light mt-1 mb-1 flex items-center justify-center ml-12" style={{ color: "#03FFE1", top: "11.5vh" }}>
                {lang.t("phase4")}
                <Tooltip placement="right" color="rgba(255, 255, 255, 0.9)" title={lang.t("phase4.tips")}>
                  <img src={exclamation} alt="" className="w-5 ml-3" />
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* bottom */}
      <div className="page__home__bottom_groups  w-full left-0 py-6">
        <div className="page__home__bottom_groups_content screen">
          <StateItem
            tips=""
            decimals={0}
            unit=""
            icon={null}
            value={store.BucketAmount}
            label={
              <div className="flex items-center justify-center">
                {lang.t("eligible.buckets")}
                <Tooltip placement="right" color="rgba(255,255,255,0.9)" title={lang.t("eligible.buckets.tips")}>
                  <img src={exclamation} alt="" className="w-4 ml-1" />
                </Tooltip>
              </div>
            }
          />
          <StateItem tips="" decimals={0} unit="" icon={null} value={store.TotalStakedAmount} label={lang.t("staked.iotex")} />
          <StateItem
            tips=""
            decimals={0}
            unit=""
            icon={null}
            value={store.TotalDroppedDevices}
            label={
              <div className="flex items-center justify-center">
                {lang.t("devices.platform")}
                <Tooltip
                  placement="right"
                  color="rgba(255,255,255,0.9)"
                  title={
                    <a href="https://iott.network/" className="c-black underline" target="_blank">
                      {lang.t("devices.platform.tips")} &gt;&gt;
                    </a>
                  }
                >
                  <img src={exclamation} alt="" className="w-4 ml-1" />
                </Tooltip>
              </div>
            }
          />
        </div>
      </div>

      <ConnectDialog isModalVisible={store.isModalVisible} handleOk={() => (store.isModalVisible = false)} handleCancel={() => (store.isModalVisible = false)} />
    </div>
  );
});
