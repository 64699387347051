import { makeAutoObservable, makeObservable, observable } from "mobx";

export class MappingState<T> {
  currentId: number | string;
  map: {
    [key: string]: T;
  };
  constructor(args: Partial<MappingState<T>>) {
    Object.assign(this, args);
    makeAutoObservable(this);
  }
  get current(): T {
    return this.map[this.currentId];
  }
  setCurrentId(val: number | string) {
    this.currentId = val;
  }
}
