import { AirDripState, RoundState } from "../src/common/store/lib/AirdripState";

export const AirdripConfig = new AirDripState({
  address: "io18ecxfw8xr80stzulcxsgt7juyvyjmks94h6k4m",
  beingDropped: 570600,
  round: [
    new RoundState({
      id: 0,
      name: "Week 1",
      range: "Apr 29 - May 5",
      amount: 450000,
      symbol: "CYC-IOTX",
      valid: true,
    }),
    new RoundState({
      id: 1,
      name: "Week 2",
      range: "May 6 - May 12",
      amount: 380000,
      symbol: "VITA-IOTX",
      valid: true,
    }),
    new RoundState({
      id: 2,
      name: "Week 3",
      range: "May 13 - May 19",
      amount: 400000,
      symbol: "SDI-IOTX",
      valid: true,
    }),
    new RoundState({
      id: 3,
      name: "Week 4",
      range: "May 20 - May 26",
      amount: "500000",
      symbol: "METX-IOTX",
      valid: true,
    }),
    new RoundState({
      id: 4,
      name: "Week 5",
      range: "May 29 - June 4",
      amount: "570600",
      symbol: "GFT-IOTX",
      valid: true,
    }),
  ],
});
