import React, { useEffect } from "react";
import "./index.scss";
import { useStore } from "../../../common/store/index";
import { useObserver, useLocalStore } from "mobx-react-lite";
import { Header } from "../../components/Header";
import { observer } from "mobx-react";
import { ConnectDialog } from "../../components/ConnectDialog";
import { Slider, InputNumber, message } from "antd";
import { IotexProvider } from "../../components/IotexProvider";
import { validateAddress } from "iotex-antenna/lib/account/utils";
import BigNumber from "bignumber.js";
import { DripTokenState } from "../../../common/store/lib/DripState";
import { IotexNetworkConfig } from "../../../../configs/NetworkConfig";
import { BigNumberInputState } from "../../../common/store/lib/BignumberInputState";

export const ProvideAirDrip = observer(() => {
  const { lang, god, drip } = useStore();
  const { maxDuration } = drip.config;
  const store = useLocalStore(() => ({
    isModalVisible: false,
    asset: "",
    amount: new BigNumberInputState({}),
    endBlockValue: 1,
    tabKey: 0,
    isNew: true,
    isAdd: false,
    addressStatus: true,
    amountStatus: true,
    currentToken: new DripTokenState({
      network: IotexNetworkConfig,
    }),
    get isApprove() {
      return store.currentToken.allowanceForDrip.value.comparedTo(store.amount.value) > 0;
    },
    get ended() {
      return drip.state.latestBlock.height >= store.currentToken.pool.endBlock;
    },
    get coinStatus() {
      console.log(store.currentToken.balance);
      return store.currentToken.tokenStatus({ amount: store.amount, balance: store.currentToken.balance });
    },
    get newMaxDuration() {
      const { startBlock, endBlock } = store.currentToken.pool;
      console.log("maxDuration", startBlock, endBlock, endBlock - startBlock, drip.config.maxDuration.value.toNumber());
      return drip.config.maxDuration.value.toNumber() + drip.state.latestBlock.height;
    },
    onChangeSlider(value: any) {
      this.endBlockValue = value;
    },
    onConnectWallet() {
      store.isModalVisible = true;
      god.iotex.activeConnector();
    },
    changeTabKey(item: number) {
      store.tabKey = item;
    },
    checkIsConnect() {
      if (!god.currentNetwork.account) {
        store.onConnectWallet();
        return false;
      }
    },
    async getAssetValue({ target: { value } }) {
      const status = validateAddress(value);
      store.addressStatus = status;
      if (status) {
        store.asset = value;
        store.currentToken.address = value;
        store.currentToken.airdrip = drip.config;
        store.updateCurrentToken();
      }
    },
    async updateCurrentToken() {
      await drip.config.loadPoolOf(store.currentToken);
      await store.currentToken.loadTokenData();
      await store.currentToken.loadBalanceOf();
      store.amount.decimals = store.currentToken.decimals;
      store.isNew = this.ended ? true : !store.currentToken.pool.exists;
      console.log("updateCurrentToken", store.isNew, store.currentToken);
    },
    async getAmountValue(value: any) {
      store.amount.setFormat(value);
      console.log(store.amount.format);
    },
    async approve() {
      try {
        //TODO: show approve loading
        store.currentToken.metas.isApprovingAllowance = true;
        // approve max
        const approveValue = new BigNumber(1.157920892373162e59);
        const res = await store.currentToken.approve({ params: [drip.config.address, approveValue.toFixed(0, 1)] });
        const receipt = await res.wait();
        if (receipt.status) {
          store.currentToken.allowanceForDrip.setValue(approveValue);
        }
        store.currentToken.metas.isApprovingAllowance = false;
      } catch (error) {
        store.currentToken.metas.isApprovingAllowance = false;
        message.warning(error.message);
      }
    },
    async addAsset() {
      try {
        store.isAdd = true;
        console.log(store.amount.value.multipliedBy(1e6).toFixed());
        const res = await drip.config.addAsset({
          params: [store.currentToken.address, store.amount.value.toFixed(0, 1), String(store.endBlockValue), String(store.amount.value.multipliedBy(1e12).toFixed())],
        });
        const receipt = await res.wait();
        console.log("addAsset", receipt, res);
        if (receipt.status === 1) {
          message.success(`Success, hash: ${res.hash}`);
        } else {
          message.warning(`Error hash: ${res.hash}`);
        }
        store.updateCurrentToken();
        store.isAdd = false;
      } catch (error) {
        console.log(error);
        message.warning(error.message);
        store.isAdd = false;
      }
    },
    async increaseSupply() {
      try {
        store.isAdd = true;
        const res = await drip.config.increaseSupply({
          params: [store.currentToken.address, store.amount.value.toFixed(0, 1)],
        });
        const receipt = await res.wait();
        if (receipt.status === 1) {
          message.success(`Success, hash: ${res.hash}`);
        } else {
          message.warning(`Error hash: ${res.hash}`);
        }
        store.amount.setFormat(0);
        store.updateCurrentToken();
        drip.loadPublicData();
        store.isAdd = false;
      } catch (error) {
        message.warning(error.message);
        store.isAdd = false;
      }
    },
    async extendDuration() {
      try {
        store.isAdd = true;
        const res = await drip.config.extendDuration({
          params: [store.currentToken.address, String(store.endBlockValue)],
        });
        console.log("extendDuration===>", res);
        const receipt = await res.wait();
        if (receipt.status === 1) {
          message.success(`Success, hash: ${res.hash}`);
        } else {
          message.warning(`Error hash: ${res.hash}`);
        }
        store.updateCurrentToken();
        drip.loadPublicData();
        store.isAdd = false;
      } catch (error) {
        message.warning(error.message);
        store.isAdd = false;
      }
    },
    maxIncreaseAmount(value: any) {
      store.amount.setFormat(value);
      console.log(store.amount.format);
    },
  }));

  useEffect(() => {
    store.currentToken = new DripTokenState({
      network: IotexNetworkConfig,
      airdrip: drip.config,
    });
    drip.loadPublicData();
  }, []);

  const assetInput = (
    <>
      <section className="flex items-start md:items-center flex-col md:flex-row">
        <div style={{ width: "100px" }}>{lang.t("asset")}</div>
        <input className="bd-aridrip_input flex-1 w-full w-full" defaultValue={store.asset} type="text" onFocus={store.checkIsConnect} onChange={store.getAssetValue} />
      </section>
      {!store.addressStatus && (
        <div className="c-red text-xs mt-2" style={{ marginLeft: 100 }}>
          {lang.t("invalid.address")}
        </div>
      )}
    </>
  );

  const amountInput = (
    <>
      <section className="flex items-start md:items-center mt-10 flex-col md:flex-row relative">
        <div style={{ width: "100px" }}>{lang.t("amount")}</div>

        {!store.isNew && store.tabKey === 1 && (
          <div className="flex items-center text-xs text-right absolute right-0 left-0 amount-balance" style={{ color: "rgba(255, 255, 255, 0.5)" }}>
            {lang.t("balance")}：<div className="truncate w-6/12">{store.currentToken.balance.format}</div> ({store.currentToken.symbol})
          </div>
        )}
        <div className="flex-1 w-full relative">
          {Number(store.amount.format) === 0 ? (
            <InputNumber className="bd-aridrip_input flex-1 w-full" type="number" onChange={store.getAmountValue} style={{ width: "100%" }} />
          ) : (
            <InputNumber className="bd-aridrip_input flex-1 w-full" type="number" value={store.amount.format} onChange={store.getAmountValue} style={{ width: "100%" }} />
          )}
          {!store.isNew && store.tabKey === 1 && (
            <div className={`absolute right-0 top-0 pr-2 text-sm md:text-md pt-1 cursor-pointer`} onClick={() => store.maxIncreaseAmount(store.currentToken.balance.format)}>
              {lang.t("max")}
            </div>
          )}
        </div>
      </section>
      {!store.coinStatus.valid && store.amount.value.comparedTo(0) !== 0 && (
        <div className="c-red text-xs mt-2" style={{ marginLeft: 100 }}>
          {lang.t("insufficient.balance")}
        </div>
      )}
    </>
  );

  const endBlock = (
    <section className="flex items-start md:items-center mt-10 flex-col md:flex-row">
      <div style={{ width: "100px" }}>{store.isNew ? lang.t("duration") : lang.t("end.block")}</div>
      <div className="flex-1 w-full flex items-center mt-5 md:mt-0">
        <section className="flex-1 mr-1 flex items-center">
          <div>Min</div>
          <div className="flex-1 mx-1">
            <Slider
              min={store.isNew ? 1 : store.currentToken.pool.endBlock + 1800}
              max={store.isNew ? maxDuration.value.toNumber() : store.newMaxDuration}
              tooltipVisible={false}
              onChange={store.onChangeSlider}
              value={typeof store.endBlockValue === "number" ? store.endBlockValue : 0}
            />
          </div>
          <div>{lang.t("max")}</div>
        </section>
        <section>
          <InputNumber
            min={store.isNew ? 1 : store.currentToken.pool.endBlock + 1800}
            max={store.isNew ? maxDuration.value.toNumber() : store.newMaxDuration}
            style={{ marginLeft: 16 }}
            value={store.endBlockValue}
            onChange={store.onChangeSlider}
          />
        </section>
      </div>
    </section>
  );

  const endDate = (
    <div className="py-2 px-4 w-full mt-3 text-xs flex flex-col md:flex-row" style={{ background: "rgba(255, 255, 255, 0.05)" }}>
      <div className="flex justify-between mb-2 md:mb-0 flex-col w-full md:w-2/5">
        <div className="flex items-cente mb-2">
          <span style={{ width: "80px" }}>{lang.t("provide.name")}：</span> {store.currentToken.pool ? store.currentToken.tokenName : "..."}
        </div>

        <div className="flex items-center">
          <span style={{ width: "80px" }}>{lang.t("end.block")}：</span> {store.currentToken.pool ? `${store.currentToken.pool.endBlock}` : "..."}
        </div>
      </div>
      <div className="flex justify-between flex-col w-full md:w-3/5">
        <div className="flex items-center mb-2">
          <span style={{ width: "102px" }}>{lang.t("end.date")}：</span> {store.currentToken.pool ? `${drip.getDateByHeight({ height: store.currentToken.pool.endBlock })}` : "..."}
        </div>
        <div className="flex items-center">
          <span style={{ width: "102px" }}>{lang.t("drip.per.block")}：</span> {store.currentToken.pool.dripPerBlock.format} ({store.currentToken.symbol})
        </div>
      </div>
    </div>
  );

  const approveBtn = useObserver(() => (
    <div
      className={`
        ${store.addressStatus && store.coinStatus.valid && !store.isApprove ? "c-cd5" : "c-disabled"} 
        ${store.currentToken.metas.isApprovingAllowance ? "loadingBtn" : ""}
        simple-button flex-1 text-center font-thin mt-0
    `}
      onClick={store.addressStatus && store.coinStatus.valid && !store.isApprove ? store.approve : null}
    >
      {lang.t("approve")}
    </div>
  ));

  const addBtn = (
    <div
      className={`
      ${store.isApprove && !store.isAdd ? "c-cd5" : "c-disabled"} 
      ${store.isAdd ? "loadingBtn" : ""}
      simple-button flex-1 text-center font-thin mt-0`}
      onClick={store.isApprove && !store.isAdd ? store.addAsset : null}
    >
      {lang.t("add")}
    </div>
  );

  const increaseSupplyBtn = (
    <div
      className={`
      ${store.isApprove && !store.isAdd ? "c-cd5" : "c-disabled"} 
      ${store.isAdd ? "loadingBtn" : ""}
      simple-button flex-1 text-center font-thin mt-0`}
      onClick={store.isApprove && !store.isAdd ? store.increaseSupply : null}
    >
      {lang.t("confirm")}
    </div>
  );

  const extendDurationBtn = (
    <div
      className={`
    ${store.isApprove && !store.isAdd ? "c-cd5" : "c-disabled"} 
    ${store.isAdd ? "loadingBtn" : ""}
    simple-button flex-1 text-center font-thin mt-0`}
      onClick={store.isApprove && !store.isAdd ? store.extendDuration : null}
    >
      {lang.t("confirm")}
    </div>
  );

  const connectBtn = (
    <div className="confirm-button px-4 w-full text-center" onClick={store.onConnectWallet}>
      {lang.t("connect")}
    </div>
  );

  return useObserver(() => (
    <div className="provide-aridrip_container c-white flex flex-col">
      <IotexProvider />
      <Header />

      <article className="flex flex-col relative">
        <div className="provide-aridrip_title text-xl lg:text-2xl xl:text-3xl relative">
          <a href="/get-airdrip" className="absolute left-0 text-lg top-1" style={{ top: "7px" }}>
            <div className="text-base opacity-50 c-white">
              {`<`} {lang.t("back")}
            </div>
          </a>
          {lang.t("provide.airdrip")}
        </div>

        <div className="provide-aridrip_content mx-auto relative">
          {store.isNew ? (
            <div className="w-full py-8  px-8 md:py-12 md:px-16" style={{ background: "rgba(255,255,255, 0.05)" }}>
              {assetInput}

              {god.currentNetwork.account ? (
                <>
                  {amountInput}
                  {endBlock}
                  <section className="flex items-start md:items-center justify-center mt-10">
                    {approveBtn}
                    <div className="mx-1" />
                    {addBtn}
                  </section>
                </>
              ) : (
                <section className="flex jusitify-center w-full mt-10">{connectBtn}</section>
              )}
            </div>
          ) : (
            <div className="w-full py-8  px-8 md:py-12 md:px-16" style={{ background: "rgba(255,255,255, 0.05)" }}>
              {assetInput}
              <div className="text-xs opacity-50 mt-3">{lang.t("asset.ongoing")}</div>
              {endDate}
              <div className="text-xs opacity-50 mt-8">{lang.t("asset.tab.title")}</div>
              <div className="flex items-center mt-3">
                <div
                  className={`${store.tabKey === 0 ? "c-cd5 active_border" : "c-white"} text-xs md:text-base border border-transparent flex-1 text-center leading-10 cursor-pointer`}
                  style={{ background: store.tabKey === 0 ? "rgba(3, 255, 225, 0.1)" : "rgba(3, 255, 225, 0.05)" }}
                  onClick={() => store.changeTabKey(0)}
                >
                  {lang.t("extend.duration")}
                </div>
                <div
                  className={`${store.tabKey === 1 ? "c-cd5 active_border" : "c-white"} text-xs md:text-base border border-transparent flex-1 text-center leading-10 cursor-pointer`}
                  style={{ background: store.tabKey === 1 ? "rgba(3, 255, 225, 0.1)" : "rgba(3, 255, 225, 0.05)" }}
                  onClick={() => store.changeTabKey(1)}
                >
                  {lang.t("increase.supply")}
                </div>
              </div>
              {store.tabKey === 0 && endBlock}
              {store.tabKey === 1 && amountInput}
              {god.currentNetwork.account ? (
                <section className="flex items-start md:items-center justify-center mt-10">
                  {approveBtn}
                  <div className="mx-1" />
                  {store.tabKey === 0 ? extendDurationBtn : increaseSupplyBtn}
                </section>
              ) : (
                <section className="flex jusitify-center w-full mt-10">{connectBtn}</section>
              )}
            </div>
          )}
        </div>
      </article>
      <ConnectDialog isModalVisible={store.isModalVisible} handleOk={() => (store.isModalVisible = false)} handleCancel={() => (store.isModalVisible = false)} />
    </div>
  ));
});
