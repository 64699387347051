import React from "react";
import { useObserver } from "mobx-react-lite";
import "./index.scss";
import { DropHistory } from "../../components/DropHistory";
import { Header } from "../../components/Header";

export const History = () => {
  return useObserver(() => (
    <div className="section__drop_history text-center">
      <Header />
      <DropHistory title="History" />
    </div>
  ));
};
