import { utils } from "../utils/index";
import { extendObservable } from "mobx";
import { enableStaticRendering } from "mobx-react-lite";
import React from "react";

import { RootStore } from "./Root";

if (utils.env.isSSR()) {
  enableStaticRendering(true);
}

export const createRootStore = () => {
  return new RootStore();
};

export function getRootStore() {
  if (utils.env.isSSR()) {
    return createRootStore();
  }

  const rootStore = createRootStore();
  ["base"].forEach((key) => {
    //@ts-ignore
    rootStore[key] = extendObservable(rootStore[key], window.__ROOT__STORE__[key]);
  });
  return rootStore;
}

export const rootStore = getRootStore();
// globalThis.store = store;

export const StoresContext = React.createContext(rootStore);

export const useStore = () => React.useContext(StoresContext);

utils.env.onBrowser(() => {
  //@ts-ignore
  window._store = rootStore;
});
