import { makeAutoObservable } from "mobx";
import { MappingState } from "./lib/MappingState";
import { NetworkState } from "./lib/NetworkState";
import { ChainState } from "./lib/ChainState";
import { IotexNetworkState } from "./lib/IotexNetworkState";
import { IotexNetworkConfig } from "../../../configs/NetworkConfig";
console.log(12345);
export class GodStore {
  network: MappingState<NetworkState> = new MappingState({
    currentId: "iotex",
    map: {
      iotex: IotexNetworkConfig,
    },
  });

  constructor() {
    makeAutoObservable(this);
    IotexNetworkConfig.god = this;
  }

  get iotex(): IotexNetworkState {
    return this.network.map.iotex as IotexNetworkState;
  }

  get isConnect() {
    return !!this.currentNetwork.account;
  }
  get currentNetwork() {
    return this.network.current;
  }
  get currentChain(): ChainState {
    return this.currentNetwork.currentChain;
  }
  get Coin() {
    return this.currentChain.Coin;
  }

  setNetwork(val: "eth" | "iotex") {
    this.network.currentId = val;
  }

  async loadPublichData() {}

  async loadBalance() {
    this.currentNetwork.loadBalance();
  }
  setShowConnecter(value: boolean) {
    this.currentNetwork.connector.showConnector = value;
  }
}
