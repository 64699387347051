import merge from "lodash/merge";
import range from "lodash/range";
import get from "lodash/get";
import set from "lodash/set";
import union from "lodash/union";
import pull from "lodash/pull";
import indexOf from "lodash/indexOf";
import xor from "lodash/xor";
import difference from "lodash/difference";
import chunk from "lodash/chunk";
import flatten from "lodash/flatten";
import each from "lodash/each";
import map from "lodash/map";
import flattenDeep from "lodash/flattenDeep";

export const _ = {
  merge,
  range,
  get,
  set,
  union,
  pull,
  indexOf,
  xor,
  difference,
  chunk,
  flatten,
  each,
  map,
  flattenDeep,
};
