import React, { useEffect, useState } from "react";
import "./index.scss";
import { Timeline, Spin } from "antd";
import { useStore } from "../../../common/store/index";
import { ClockCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { observer } from "mobx-react";
import BottomScrollListener from "react-bottom-scroll-listener";
import { moment } from "../../utils/moment";
import { publicConfig } from "../../../../configs/public";
import BigNumber from "bignumber.js";
import { numberWithCommas } from "../../utils/index";

const HyperLinkImg = require("../../static/images/hyperlink.svg");
const iconBurnt = require("../../static/images/icon_burnt.png");
const iconDropped = require("../../static/images/icon_dropped.png");
const iconAirdrip = require("../../static/images/icon_dropped_gft.png");

interface IComponentProps {
  title: string;
  type?: "drop" | "burn";
}
const burnDate = moment("2020-11-03");

export const DropHistory = observer((props: IComponentProps) => {
  const { title, type = "drop" } = props;
  const { lang } = useStore();
  const { burnDrop } = useStore();

  useEffect(() => {
    burnDrop.loadStats();
  }, []);

  if (burnDrop.isLoadingStats || !burnDrop.stats.length)
    return (
      <div className="w-full flex items-center justify-center" style={{ height: "calc(100% - 10rem)" }}>
        <Spin indicator={<LoadingOutlined style={{ fontSize: 50 }} spin />} />
      </div>
    );
  const timelineItems = [];
  let lastMonth = moment(burnDrop.stats[0]?.batch_id, "YYYYMMDD").format("M/YYYY");
  timelineItems.push(
    <Timeline.Item
      className="component__drop_history__timeline__item__date"
      color="white"
      dot={<ClockCircleOutlined className="c-white text-2xl sm:text-xl md:text-2xl lg:text-3xl" />}
      key={lastMonth}
    >
      <div className="c-white font-thin text-lg sm:text-xl lg:text-3xl font-normal py-1 sm:py-1 md:py-2 lg:py-2">&nbsp;{lastMonth}</div>
    </Timeline.Item>
  );
  burnDrop.stats.forEach((action, index) => {
    // const curMonth = moment(action.batch_id, "YYYYMMDD").format("M");
    const curDate = moment(action.batch_id, "YYYYMMDD");
    const sameMontrh = moment(burnDrop.stats[0]?.batch_id, "YYYYMMDD").isSame(curDate, "months");
    const curDay = sameMontrh ? curDate.format("D") : curDate.format("MM-D");
    const diffDays = curDate.diff(burnDate, "days");
    const burnAmount = new BigNumber(action.burnt).dividedBy(1e18).toFixed();
    const dropAmount = new BigNumber(action.dropped).dividedBy(1e18).toFixed();
    const dripAmount = new BigNumber(action.drip_amount).dividedBy(1e18).toFixed();

    timelineItems.push(
      <Timeline.Item
        className="component__drop_history__timeline__item__data"
        key={`${action.created_at}`}
        color="white"
        dot={<div className="component__drop_history__timeline__item__dot">{curDay}</div>}
      >
        <div
          className={`c-white font-normal float-left flex items-baseline justify-between whitespace-no-wrap text-sm sm:text-md md:text-lg lg:text-xl ${
            timelineItems.length & 1 ? "flex-row" : "flex-row-reverse"
          }`}
        >
          {index === 0 && (
            <div className="component__drop_history__timeline_tips w-full absolute top-0 pb-4" style={{ top: "-115px" }}>
              <div className="component__drop_history_top">
                <div className="component__drop_history_top_item">
                  <img src={iconBurnt} alt="" />
                  <p className="c-white text-sm md:text-xl text-center">{lang.t("burn")}</p>
                </div>
                <div className="component__drop_history_top_item">
                  <img src={iconDropped} alt="" />
                  <p className="c-white text-sm md:text-xl text-center">{lang.t("drop")}</p>
                </div>
                <div className="component__drop_history_top_item">
                  <img src={iconAirdrip} alt="" />
                  <p className="c-white text-sm md:text-xl text-center">{lang.t("Drip")}</p>
                </div>
              </div>
            </div>
          )}
          <div className="flex w-full justify-between absolute right-0">
            <span className={`whitespace-normal text-left`}>{numberWithCommas(burnAmount)} IOTX</span>
            <span className={`whitespace-normal text-center`}>{numberWithCommas(dropAmount)} IOTX</span>
            <span className={`whitespace-normal text-right`}>{numberWithCommas(dripAmount)} LP</span>
          </div>
        </div>
      </Timeline.Item>
    );
  });

  return (
    <>
      <div className="component__drop_history__title flex flex-row items-end justify-center c-white text-xl mb-2 sm:text-2xl sm:mb-2 md:text-3xl md:mb-3 lg:text-4xl lg:mb-4">
        {title}
        <a className="w-6 ml-4 sm:ml-5 md:w-6 md:ml-6 lg:w-8" href={`https://iotexscan.io/address/${publicConfig.BURNDROP_CONTRACT_ADDRESS}`}>
          <img alt="hyper_img" src={HyperLinkImg} />
        </a>
      </div>
      <div className="c-white font-thin text-base px-4 leading-snug mb-4 sm:text-lg sm:leading-normal sm:mb-8 md:text-xl md:leading-normal md:mb-12 lg:text-xl lg:leading-normal lg:mb-16">
        {lang.t("history.tips")}
      </div>
      <div className="component__drop_history fade relative">
        <BottomScrollListener
          triggerOnNoScroll={false}
          debounce={500}
          onBottom={() => {
            if (!burnDrop.isLoadingStats && burnDrop.hasMore) {
              burnDrop.loadStats();
            }
          }}
        >
          {(scrollRef) => (
            <div ref={scrollRef as any} className="component__drop_history__timeline__content px-0  md:px-4">
              <Timeline mode="alternate" className="component__drop_history__timeline component__drop_history__timeline--fixed">
                {timelineItems[0]}
              </Timeline>
              <Timeline mode="alternate" className="component__drop_history__timeline component__drop_history__timeline--scrollable">
                {timelineItems}
              </Timeline>
            </div>
          )}
        </BottomScrollListener>
      </div>
    </>
  );
});
