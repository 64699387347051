import { observable, action, computed, makeAutoObservable } from "mobx";
import remotedev from "mobx-remotedev";
import en from "../../../public/translations/en.json";
import axios from "axios";
import { utils } from "../utils/index";
import { _ } from "../utils/lodash";

type Translation = typeof en;

const fileNameMapping = {
  "en-US": "en",
  "zh-CN": "zh_CN",
};

export class LangStore {
  langPath = "/translations";
  lang: string = "en";
  translations: { [key: string]: Translation } = {
    en,
  };
  constructor() {
    makeAutoObservable(this);
  }

  get translation() {
    return this.translations[this.lang];
  }

  async init() {
    const urlParams = new URLSearchParams(window.location.search);
    const langFromQuery = urlParams.get("lang");
    const broswerLang = _.get(navigator.languages, "0", navigator.language);
    let lang = langFromQuery || localStorage.getItem("lang") || broswerLang;
    if (fileNameMapping[lang]) {
      lang = fileNameMapping[lang];
    }
    await this.loadTranslation(lang);
    this.lang = lang;
  }

  async setLang(lang: string) {
    await this.loadTranslation(lang);
    localStorage.setItem("lang", lang);
    this.lang = lang;
  }

  async loadTranslation(lang) {
    if (this.translations[lang]) {
      return;
    }
    const res = await axios.get(`${this.langPath}/${lang}.json`);
    if (res.data) {
      this.translations[lang] = res.data;
    }
  }

  t(str: keyof Translation, data?: { [key: string]: any }) {
    if (utils.env.isSSR()) {
      return str;
    }
    let processed = this.translation[str] || this.translations.en[str];
    if (!processed) {
      return str;
    }
    if (data) {
      Object.keys(data).forEach((key) => {
        processed = processed.replace(`\${${key}}`, data[key]);
      });
    }

    return processed;
  }
}
